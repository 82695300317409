import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import classNames from 'classnames';
import userSelectedPreference from '../../lib/userSelectedPreference';

class Tabs extends Component {
  static propTypes = {
    initialValues: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    url: PropTypes.shape().isRequired,
    store: PropTypes.shape({
      dispatch: PropTypes.func.isRequired,
    }).isRequired,
    components: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    useGeneralForm: PropTypes.bool,
    onTabChangeCallback: PropTypes.func,
  };

  static defaultProps = {
    initialValues: {},
    useGeneralForm: false,
  };

  constructor(props) {
    super(props);
    const { url, user } = this.props;
    let currentComponent = 0;
    let currentPageUrl = '';
    let rowIndex = -1;
    const tempPreference = userSelectedPreference.getUserSelectedPreference();
    if (tempPreference && tempPreference.length > 0) {
      rowIndex = userSelectedPreference.checkPreferenceExistByUserAndUrl({
        currentPageUrl: url.asPath,
        userId: user && user.id,
        tempPreference,
      });
      if (rowIndex >= 0) {
        currentPageUrl = tempPreference[rowIndex].currentPageUrl;
      }
    }
    if (currentPageUrl === url.asPath) {
      if (tempPreference[rowIndex].clickedCurrentComponent) {
        currentComponent = parseInt(tempPreference[rowIndex].clickedCurrentComponent);
      }
    }
    this.state = {
      data: {},
      currentComponent,
      currentInnerComponent: 0,
    };
  }

  getRenderedTabs = () => {
    const { components, url, user, onTabChangeCallback } = this.props;
    const { currentComponent } = this.state;
    return (
      <ul className="nav nav-tabs custom-tabs responsive">
        {components.map((component, index) => (
          <li
            key={component.header}
            data-testid={`tab-${index}`}
            className={`${classNames({ active: currentComponent === index })} tabs-item`}
          >
            <a
              className={classNames({ 'long-name-tab': component.isLong })}
              onClick={() => {
                this.setState({
                  currentComponent: index,
                  currentInnerComponent: 0,
                });
                if (onTabChangeCallback) onTabChangeCallback(index);
                if (
                  component.header !== '' &&
                  component.header.toLowerCase() !== 'activity' &&
                  component.header.toLowerCase() !== 'subscription' &&
                  component.header.toLowerCase() !== 'billing'
                ) {
                  userSelectedPreference.setUserSelectedPreference({
                    currentPageUrl: url.asPath,
                    tabIndex: index,
                    userId: user && user.id,
                  });
                }
              }}
            >
              {component.header}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  getRenderedContent = () => {
    const { components, handleSubmit, store, url, t } = this.props;

    if (components.length === 0) return <div className="form-horizontal" />;

    const { data, currentComponent, currentInnerComponent } = this.state;
    const RenderedComponent = components[currentComponent].content[currentInnerComponent];
    const content = React.cloneElement(RenderedComponent, {
      data,
      handleSubmit,
      store,
      url,
      t,
      changeData: this.changeData,
      showNewComponent: this.showNewComponent,
      showNewInnerComponent: this.showNewInnerComponent,
    });
    return <div className="form-horizontal tab-content-custom">{content}</div>;
  };

  changeData = (newData) => {
    this.setState({
      data: newData,
    });
  };

  showNewComponent = (newComponent) => {
    this.setState({
      currentComponent: newComponent,
    });
  };

  showNewInnerComponent = (newInnerComponent) => {
    this.setState({
      currentInnerComponent: newInnerComponent,
    });
  };

  render() {
    const { useGeneralForm } = this.props;
    return (
      <div className="row row-nav-tabs p-sm">
        {this.getRenderedTabs()}
        <div className="tab-content responsive">
          {useGeneralForm ? <form>{this.getRenderedContent()}</form> : this.getRenderedContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  initialValues: props.initialValues,
  user: state && state.user && state.user.currentuser,
});

export default connect(
  mapStateToProps,
  null,
)(
  reduxForm({
    form: 'tabsForm',
    enableReinitialize: true,
  })(Tabs),
);
