import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, change, reduxForm } from 'redux-form';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FormInput, ReduxFormSelect, InputWithoutLabel, ReduxFormMultiSelect } from './parts';
import { RangeMonthPicker } from '../picker';
import { renderCategoryGroups, renderFilteredCategoryGroups } from './CategoryGroupsHandler';

import { getCompanyContactList } from '../../actions/oms/InternalContactActions';
import { getCompanyAddressList } from '../../actions/oms/InternalAddressActions';

class CreateBuildingBudgetForm extends Component {
  static propTypes = {
    addCategoryForm: PropTypes.shape().isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    categoryGroups: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    customerList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    customerContactList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    customerAddressList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    getCompanyContactList: PropTypes.func.isRequired,
    getCompanyAddressList: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    changeDates: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    store: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);
    const categoryGroups = renderCategoryGroups(props.categoryGroups, props.categories);

    this.state = {
      categoryGroups,
      isCompanySelected: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { categoryGroups, categories } = nextProps;
    if (categoryGroups !== this.props.categoryGroups) {
      const newCategoryGroups = renderCategoryGroups(categoryGroups, categories);
      this.setState({
        categoryGroups: newCategoryGroups,
      });
    }
    const form = nextProps.addCategoryForm.addBuildingBudgetForm;
    if (form && form.values && form.values.categories) {
      const formCategories = form.values.categories;
      const chosenCategories = formCategories.map((category) => category.name);
      const newCategoryGroups = renderFilteredCategoryGroups(categoryGroups, categories, chosenCategories);
      this.setState({
        categoryGroups: newCategoryGroups,
      });
    }
  }

  renderCategories = ({ fields, meta: { error, submitFailed } }) => {
    const { categoryGroups } = this.state;
    const { store, t } = this.props;
    const SPLIT_VALUES = [
      t('Every month'),
      t('Every two month'),
      t('Every three month'),
      t('Every six month'),
      t('Once a year'),
    ];
    const categoryGroupNames = categoryGroups.map((group) => group.name);
    const categoryNames = categoryGroups.map((group) => group.categories);
    return (
      <ul className="add-building-categories-form">
        <li>
          <div className="dynamic-form-wrapper">
            <button className="filter-button long-button" type="button" onClick={() => fields.push({})}>
              {this.props.t('Add category')}
            </button>
            {submitFailed && error && <span>{error}</span>}
          </div>
        </li>
        {fields.map((category, index) => {
          const currentGroupName = fields.getAll()[index].group;
          const categoriesIndex = _.findIndex(categoryGroupNames, (name) => name === currentGroupName);
          return (
            <li key={index}>
              <div className="dynamic-form-wrapper">
                <div className="dynamic-form-inner-wrapper dynamic-header">
                  <div className="add-category-header">{this.props.t('Category group')}</div>
                  <div
                    className={classNames('add-category-header', {
                      'header-disabled': categoriesIndex === -1 && currentGroupName !== 'none',
                    })}
                  >
                    {this.props.t('Category')}
                  </div>
                  <div className="add-category-header">{this.props.t('Total')}</div>
                </div>
                <div className="dynamic-form-inner-wrapper">
                  <Field
                    name={`${category}.group`}
                    component={ReduxFormSelect}
                    options={categoryGroupNames}
                    onChange={() => {
                      store.dispatch(change('addBuildingBudgetForm', `${category}.name`, null));
                    }}
                    label={this.props.t('Category group')}
                    placeholder={this.props.t('Category group')}
                    width="200px"
                  />

                  {categoriesIndex || currentGroupName === 'none' ? (
                    <Field
                      name={`${category}.name`}
                      component={ReduxFormSelect}
                      options={categoryNames[categoriesIndex] || []}
                      label={this.props.t('Name')}
                      placeholder={this.props.t('Category')}
                      disabled={categoriesIndex === -1}
                      width="200px"
                    />
                  ) : (
                    <div className="add-category-name-gap" />
                  )}

                  <Field
                    name={`${category}.total`}
                    type="text"
                    component={InputWithoutLabel}
                    placeholder={this.props.t('total')}
                  />

                  <button
                    type="button"
                    className="filter-button"
                    title="Remove category"
                    onClick={() => {
                      fields.remove(index);
                    }}
                  >
                    {this.props.t('Remove')}
                  </button>
                </div>

                <div className="dynamic-form-inner-wrapper dynamic-header">
                  <div className="add-category-header">{this.props.t('Planned')}</div>
                  <div className="add-category-header">{this.props.t('Split values')}</div>
                  <div className="add-category-header">{this.props.t('Unplanned')}</div>
                </div>

                <div className="dynamic-form-inner-wrapper">
                  <Field
                    name={`${category}.planned`}
                    type="text"
                    component={InputWithoutLabel}
                    placeholder={this.props.t('planned')}
                  />

                  <Field
                    name={`${category}.split`}
                    component={ReduxFormSelect}
                    options={SPLIT_VALUES}
                    label={this.props.t('Split values')}
                    placeholder={this.props.t('Split values')}
                    width="200px"
                    defaultValue={SPLIT_VALUES[0]}
                  />

                  <Field
                    name={`${category}.unplanned`}
                    type="text"
                    component={InputWithoutLabel}
                    placeholder={this.props.t('unplanned')}
                  />
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  onCompanyChange = (event, data) => {
    if (data && data.length && data[0].value) {
      const index = _.findIndex(this.props.customerList, { uuid: data[0].value });

      if (~index) {
        this.setState({
          isCompanySelected: true,
        });
        this.props.getCompanyContactList(data[0].value);
        this.props.getCompanyAddressList(data[0].value);
        return;
      }
    }

    this.setState({
      isCompanySelected: false,
    });
  };

  render() {
    const { changeDates, onSubmit, customerList, customerContactList, customerAddressList, t } = this.props;

    const { isCompanySelected } = this.state;

    return (
      <div className="container-fluid white-bg">
        <div className="col-lg-12">
          <div className="row row-title p-sm">
            <form className="form-horizontal col-md-12">
              <div className="row">
                <div className="col-lg-8 col-md-10 col-sm-12">
                  <button className="filter-button" type="submit" onClick={onSubmit}>
                    {t('Submit')}
                  </button>

                  <Field
                    label={t('Amount')}
                    component={FormInput}
                    name="amount"
                    type="number"
                    placeholder={t('Enter amount')}
                  />

                  <div className="form-group">
                    <label className="col-sm-4 control-label">{t('Company')}</label>

                    <div className="col-sm-8">
                      <Field
                        name="internalCompany"
                        component={ReduxFormMultiSelect}
                        options={customerList.map((item) => ({
                          label: item.name,
                          value: item.uuid,
                        }))}
                        disableCreate
                        useSingleValue
                        placeholder={t('Choose company')}
                        onChange={this.onCompanyChange}
                      />
                    </div>
                  </div>
                  {isCompanySelected && (
                    <div className="form-group">
                      <label className="col-sm-4 control-label">{t('Billing contact')}</label>

                      <div className="col-sm-8">
                        <Field
                          name="billingContact"
                          component={ReduxFormMultiSelect}
                          options={customerContactList.map((item) => ({
                            label: `${item.firstName} ${item.lastName}`,
                            value: item.id,
                          }))}
                          disableCreate
                          useSingleValue
                          placeholder={t('Choose billing contact')}
                        />
                      </div>
                    </div>
                  )}

                  {isCompanySelected && (
                    <div className="form-group">
                      <label className="col-sm-4 control-label">{t('Billing address')}</label>

                      <div className="col-sm-8">
                        <Field
                          name="billingAddress"
                          component={ReduxFormMultiSelect}
                          options={customerAddressList.map((item) => ({
                            label: item.line1,
                            value: item.id,
                          }))}
                          disableCreate
                          useSingleValue
                          placeholder={t('Choose billing address')}
                        />
                      </div>
                    </div>
                  )}

                  {isCompanySelected && (
                    <div className="form-group">
                      <label className="col-sm-4 control-label">{t('Shipping contact')}</label>

                      <div className="col-sm-8">
                        <Field
                          name="shippingContact"
                          component={ReduxFormMultiSelect}
                          options={customerContactList.map((item) => ({
                            label: `${item.firstName} ${item.lastName}`,
                            value: item.id,
                          }))}
                          disableCreate
                          useSingleValue
                          placeholder={t('Choose shipping contact')}
                        />
                      </div>
                    </div>
                  )}

                  {isCompanySelected && (
                    <div className="form-group">
                      <label className="col-sm-4 control-label">{t('Shipping address')}</label>

                      <div className="col-sm-8">
                        <Field
                          name="shippingAddress"
                          component={ReduxFormMultiSelect}
                          options={customerAddressList.map((item) => ({
                            label: item.line1,
                            value: item.id,
                          }))}
                          disableCreate
                          useSingleValue
                          placeholder={t('Choose shipping address')}
                        />
                      </div>
                    </div>
                  )}

                  <div className="form-group">
                    <label className="col-sm-4 control-label">{t('Date range')}</label>

                    <div className="col-sm-8">
                      <RangeMonthPicker
                        changeDates={changeDates}
                        placeholderStart={t('Pick start date')}
                        placeholderEnd={t('Pick end date')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <FieldArray name="categories" component={this.renderCategories} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    omsInternalContact: { customerContactList },
    omsInternalAddress: { customerAddressList },
  } = state;

  return {
    customerContactList,
    customerAddressList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCompanyContactList,
      getCompanyAddressList,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'addBuildingBudgetForm',
  })(CreateBuildingBudgetForm),
);
