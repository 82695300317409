import React from 'react';

const LoginFormInput = ({ input, label, name, type, focus, autoComplete, meta: { touched, error } }) => (
  <div className="form-group">
    <label className="col-xs-12 col-sm-4 control-label" htmlFor={name}>
      {label}
    </label>

    <div className="col-xs-12 col-sm-8">
      <input
        {...input}
        className="form-control valid"
        type={type}
        name={name}
        id={name}
        placeholder={label}
        autoFocus={focus}
      />
    </div>
  </div>
);

export default LoginFormInput;
