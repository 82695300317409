import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { FormInput } from './parts';

const CreateCategoryGroupForm = ({ onSubmit, t }) => (
  <div className="container-fluid white-bg">
    <div className="col-lg-12">
      <div className="row row-title p-sm">
        <form className="form-horizontal col-sm-12 col-md-10 col-lg-8">
          <div className="row">
            <div className="col-md-12">
              <button className="filter-button" type="submit" onClick={onSubmit}>
                {t('Save')}
              </button>

              <Field label={t('Name')} component={FormInput} name="name" type="text" placeholder={t('Name')} />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
);

CreateCategoryGroupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default CreateCategoryGroupForm;
