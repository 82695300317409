import { LOAD_CUSTOMER_ADDRESS_LIST, SHOW_LOADER, HIDE_LOADER } from '../types';

import { api } from '../../lib/api';
import { Router } from '../../../common/routes';

import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/internalAddress';

export const loadAddressList = (addressList) => ({
  type: LOAD_CUSTOMER_ADDRESS_LIST,
  payload: addressList,
});

export const getCompanyAddressList = (internalCompanyUUID) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  dispatch(loadAddressList([]));
  api
    .get(`${routePath}/get-list-by-internal-company/${internalCompanyUUID}`)
    .then((response) => {
      dispatch(loadAddressList(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getCompanyAddressList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
