import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormInput } from './parts';
import RolePermissionTabs from '../tabs/RolePermissionTabs';

const EditRoleForm = ({
  onSubmit,
  t,
  permissionsListData,
  selectedRole,
  handleNameChange,
  handlePermisssionUpdate,
  url,
  store,
  onChangeJmsModulePermissionView,
  stateData,
  setPermission,
  onChangeCafmModulePermissionView,
  onChangeAlpModulePermissionView,
  onChangeAddOnsModulePermissionView,
}) => (
  <div className="container-fluid">
    <div className="row row-title p-sm ">
      <div className="col-lg-12">
        <button className="filter-button" type="submit" onClick={onSubmit}>
          {t('Save')}
        </button>
      </div>
    </div>
    <form className="form-horizontal">
      <div className="row">
        <div className="col-md-12">
          <Field
            label={t('Name *')}
            component={FormInput}
            name="name"
            type="text"
            placeholder="Enter name"
            isShort
            input={{ value: selectedRole.name, onChange: handleNameChange }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <RolePermissionTabs
            store={store}
            url={url}
            t={t}
            selectedPermissions={selectedRole.RolePermissions}
            handlePermisssionUpdate={handlePermisssionUpdate}
            permissionsListData={permissionsListData}
            onChangeJmsModulePermissionView={onChangeJmsModulePermissionView}
            stateData={stateData}
            setPermission={setPermission}
            onChangeCafmModulePermissionView={onChangeCafmModulePermissionView}
            onChangeAlpModulePermissionView={onChangeAlpModulePermissionView}
            onChangeAddOnsModulePermissionView={onChangeAddOnsModulePermissionView}
          />
        </div>
      </div>
    </form>
  </div>
);

EditRoleForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  permissionsListData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedRole: PropTypes.shape(),
  handleNameChange: PropTypes.func.isRequired,
  handlePermisssionUpdate: PropTypes.func.isRequired,
};

export default EditRoleForm;
