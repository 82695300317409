import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { permissions } from '../../constants/permissions';
import commonFunction from '../../lib/commonFunction';
import { FormLabel } from './parts';

class JMSRolePermissionsFields extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    permissionsListData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    selectedPermissions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    handlePermisssionUpdate: PropTypes.func,
    onChangeJmsModulePermissionView: PropTypes.func,
    stateData: PropTypes.arrayOf(PropTypes.shape()),
    onChangeJmsPermission: PropTypes.func,
    renderCheckbox: PropTypes.func.isRequired,
    renderSelector: PropTypes.func.isRequired,
    getPermissionInfoByKey: PropTypes.func,
    getPermissionInfoForDropdownOptions: PropTypes.func,
    entitledApplicationFeatureList: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    handlePermisssionUpdate: undefined,
    permissionsListData: [],
    selectedPermissions: [],
    onChangeJmsModulePermissionView: undefined,
    stateData: [],
    onChangeJmsPermission: undefined,
    getPermissionInfoByKey: undefined,
    getPermissionInfoForDropdownOptions: undefined,
    entitledApplicationFeatureList: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      ...props.stateData,
    };
  }

  onChangeJmsModulePermissionView = (value, flag) => {
    value = typeof value === 'object' ? value.value : value;
    this.props.onChangeJmsModulePermissionView(value, flag);
  };

  onChangeJmsPermission = (value) => {
    this.props.onChangeJmsPermission(value, 'jms');
  };

  onChangeJmsPermissionWithFeature = (value, features) => {
    this.props.onChangeJmsPermission(value, 'jms');
    if (features.canSeeAdvancedInvoices && typeof value.jmsJobSalesInvoiceView !== 'undefined') {
      this.props.onChangeJmsPermission({ jmsJobSalesInvoiceAdvanced: value.jmsJobSalesInvoiceView }, 'jms');
    } else if (features.canSeeAdvancedInvoices && typeof value.jmsPOPurchaseInvoiceView !== 'undefined') {
      this.props.onChangeJmsPermission({ jmsPOPurchaseInvoiceAdvanced: value.jmsPOPurchaseInvoiceView }, 'jms');
    }
  };

  render() {
    const { t, entitledApplicationFeatureList } = this.props;

    let {
      jmsAccess,
      jmsJobsView,
      jmsQuotesView,
      jmsPurchaseOrdersView,
      jmsCompaniesView,
      jmsCompaniesContactsView,
      jmsCompaniesAddressesView,
      jmsJobVisitsView,
      jmsJobVisitsTimeLogView,
    } = this.props.stateData;

    let jmsFeatureInfo = {};
    if (jmsAccess) {
      jmsFeatureInfo = commonFunction.getEntitledAccountApplicationFeature({
        application: 'jms',
        entitledApplicationFeatureList,
      });
    }

    jmsJobsView = !jmsJobsView ? 'none' : jmsJobsView;
    jmsQuotesView = !jmsQuotesView ? 'none' : jmsQuotesView;
    jmsPurchaseOrdersView = !jmsPurchaseOrdersView ? 'none' : jmsPurchaseOrdersView;
    jmsCompaniesView = !jmsCompaniesView ? 'none' : jmsCompaniesView;
    jmsCompaniesContactsView = !jmsCompaniesContactsView ? 'none' : jmsCompaniesContactsView;
    jmsCompaniesAddressesView = !jmsCompaniesAddressesView ? 'none' : jmsCompaniesAddressesView;
    jmsJobVisitsView = !jmsJobVisitsView ? 'none' : jmsJobVisitsView;
    jmsJobVisitsTimeLogView = !jmsJobVisitsTimeLogView ? 'none' : jmsJobVisitsTimeLogView;

    const memberMinMenu = [
      { key: 'none', value: 'no access' },
      { key: 'own', value: 'access to assigned only' },
    ];
    const memberFullMenu = [...memberMinMenu, { key: 'all', value: 'access to all' }];

    const jmsViewMenu = memberFullMenu;
    const jmsJobsSubMenu = jmsJobsView === 'own' ? memberMinMenu : memberFullMenu;
    const jmsQuotesSubMenu = jmsQuotesView === 'own' ? memberMinMenu : memberFullMenu;
    const jmsPurchaseOrdersSubMenu = jmsPurchaseOrdersView === 'own' ? memberMinMenu : memberFullMenu;
    const jmsCompaniesSubMenu = jmsCompaniesView === 'own' ? memberMinMenu : memberFullMenu;

    const jmsJobFullyInvoicedSubMenu = [
      { key: 'none', value: 'no access' },
      { key: 'own', value: 'access to assigned only' },
    ];

    const jmsJobVisitsSubMenu = jmsJobVisitsView === 'own' ? memberMinMenu : memberFullMenu;
    const jmsJobVisitsTimeLogSubMenu = jmsJobVisitsTimeLogView === 'own' ? memberMinMenu : memberFullMenu;

    return (
      <div className="p-sm">
        {!jmsAccess && (
          <div>
            <h3>Please grant JMS Access to set permissions</h3>
          </div>
        )}
        {jmsAccess && (
          <div>
            {jmsFeatureInfo.canSeeQuote && (
              <div>
                <h3>Quotes</h3>
                <div className="form-group">
                  <FormLabel
                    label="View"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.jms.quotes.View)}
                  />{' '}
                  {this.props.renderSelector(
                    permissions.jms.quotes.View,
                    jmsQuotesView,
                    (val) => this.onChangeJmsModulePermissionView(val, 'quotes'),
                    this.props.getPermissionInfoForDropdownOptions(jmsViewMenu, permissions.jms.quotes.View),
                  )}
                </div>
              </div>
            )}
            {jmsFeatureInfo.canSeeQuote && jmsQuotesView && jmsQuotesView !== 'none' && (
              <div>
                <div className="form-group">
                  <FormLabel
                    label="Edit"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.jms.quotes.Edit)}
                  />{' '}
                  {this.props.renderSelector(
                    permissions.jms.quotes.Edit,
                    this.props.stateData.jmsQuotesEdit,
                    (val) => this.onChangeJmsPermission({ jmsQuotesEdit: val.value }),
                    this.props.getPermissionInfoForDropdownOptions(jmsQuotesSubMenu, permissions.jms.quotes.Edit),
                  )}
                </div>
                {this.props.renderCheckbox(
                  permissions.jms.quotes.Create,
                  'Create',
                  !this.props.stateData.jmsQuotesCreate ? false : this.props.stateData.jmsQuotesCreate,
                  () =>
                    this.onChangeJmsPermission({
                      jmsQuotesCreate: !this.props.stateData.jmsQuotesCreate,
                    }),
                )}
                <div className="form-group">
                  <FormLabel
                    label="Delete"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.jms.quotes.Delete)}
                  />{' '}
                  {this.props.renderSelector(
                    permissions.jms.quotes.Delete,
                    this.props.stateData.jmsQuotesDelete,
                    (val) => this.onChangeJmsPermission({ jmsQuotesDelete: val.value }),
                    this.props.getPermissionInfoForDropdownOptions(jmsQuotesSubMenu, permissions.jms.quotes.Delete),
                  )}
                </div>
              </div>
            )}

            {jmsFeatureInfo.canSeeJob && (
              <div>
                <h3>Jobs</h3>
                <div className="form-group">
                  <FormLabel
                    label="View"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.jms.jobs.View)}
                  />{' '}
                  {this.props.renderSelector(
                    permissions.jms.jobs.View,
                    jmsJobsView,
                    (val) => this.onChangeJmsModulePermissionView(val, 'jobs'),
                    this.props.getPermissionInfoForDropdownOptions(jmsViewMenu, permissions.jms.jobs.View),
                  )}
                </div>
              </div>
            )}
            {jmsFeatureInfo.canSeeJob && jmsJobsView && jmsJobsView !== 'none' && (
              <div>
                <div className="form-group">
                  <FormLabel
                    label="Edit"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.jms.jobs.Edit)}
                  />{' '}
                  {this.props.renderSelector(
                    permissions.jms.jobs.Edit,
                    this.props.stateData.jmsJobsEdit,
                    (val) => this.onChangeJmsPermission({ jmsJobsEdit: val.value }),
                    this.props.getPermissionInfoForDropdownOptions(jmsJobsSubMenu, permissions.jms.jobs.Edit),
                  )}
                </div>
                {this.props.renderCheckbox(
                  permissions.jms.jobs.Create,
                  'Create',
                  !this.props.stateData.jmsJobsCreate ? false : this.props.stateData.jmsJobsCreate,
                  () => this.onChangeJmsPermission({ jmsJobsCreate: !this.props.stateData.jmsJobsCreate }),
                )}
                <div className="form-group">
                  <FormLabel
                    label="Delete"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.jms.jobs.Delete)}
                  />{' '}
                  {this.props.renderSelector(
                    permissions.jms.jobs.Delete,
                    this.props.stateData.jmsJobsDelete,
                    (val) => this.onChangeJmsPermission({ jmsJobsDelete: val.value }),
                    this.props.getPermissionInfoForDropdownOptions(jmsJobsSubMenu, permissions.jms.jobs.Delete),
                  )}
                </div>

                <div className="form-group">
                  <FormLabel label="Unlock" isShort />{' '}
                  {this.props.renderSelector(
                    permissions.jms.jobs.Unlock,
                    this.props.stateData.jmsJobsUnlock,
                    (val) => this.onChangeJmsPermission({ jmsJobsUnlock: val.value }),
                    this.props.getPermissionInfoForDropdownOptions(jmsJobsSubMenu, permissions.jms.jobs.Unlock),
                  )}
                </div>

                {this.props.renderCheckbox(
                  permissions.jms.jobs.ViewArchived,
                  'View Archived',
                  !this.props.stateData.jmsJobsViewArchived ? false : this.props.stateData.jmsJobsViewArchived,
                  () => this.onChangeJmsPermission({ jmsJobsViewArchived: !this.props.stateData.jmsJobsViewArchived }),
                )}
                {this.props.renderCheckbox(
                  permissions.jms.jobs.EditArchived,
                  'Edit Archived',
                  !this.props.stateData.jmsJobsEditArchived ? false : this.props.stateData.jmsJobsEditArchived,
                  () => this.onChangeJmsPermission({ jmsJobsEditArchived: !this.props.stateData.jmsJobsEditArchived }),
                )}

                {jmsFeatureInfo.canSeeInvoices &&
                  this.props.renderCheckbox(
                    permissions.jms.jobSalesInvoice.View,
                    'View Sales Invoice',
                    !this.props.stateData.jmsJobSalesInvoiceView ? false : this.props.stateData.jmsJobSalesInvoiceView,
                    () =>
                      this.onChangeJmsPermissionWithFeature(
                        {
                          jmsJobSalesInvoiceView: !this.props.stateData.jmsJobSalesInvoiceView,
                        },
                        jmsFeatureInfo,
                      ),
                  )}
                {jmsFeatureInfo.canSeeInvoices &&
                  this.props.renderCheckbox(
                    permissions.jms.jobSalesInvoice.Create,
                    'Create Sales Invoice',
                    !this.props.stateData.jmsJobSalesInvoiceCreate
                      ? false
                      : this.props.stateData.jmsJobSalesInvoiceCreate,
                    () =>
                      this.onChangeJmsPermission({
                        jmsJobSalesInvoiceCreate: !this.props.stateData.jmsJobSalesInvoiceCreate,
                      }),
                  )}
                {jmsFeatureInfo.canSeeInvoices &&
                  this.props.renderCheckbox(
                    permissions.jms.jobSalesInvoice.Edit,
                    'Edit Sales Invoice',
                    !this.props.stateData.jmsJobSalesInvoiceEdit ? false : this.props.stateData.jmsJobSalesInvoiceEdit,
                    () =>
                      this.onChangeJmsPermission({
                        jmsJobSalesInvoiceEdit: !this.props.stateData.jmsJobSalesInvoiceEdit,
                      }),
                  )}
                {jmsFeatureInfo.canSeeInvoices &&
                  this.props.renderCheckbox(
                    permissions.jms.jobSalesInvoice.Delete,
                    'Delete Sales Invoice',
                    !this.props.stateData.jmsJobSalesInvoiceDelete
                      ? false
                      : this.props.stateData.jmsJobSalesInvoiceDelete,
                    () =>
                      this.onChangeJmsPermission({
                        jmsJobSalesInvoiceDelete: !this.props.stateData.jmsJobSalesInvoiceDelete,
                      }),
                  )}
              </div>
            )}

            {jmsFeatureInfo.canSeePurchaseOrder && (
              <div>
                <h3>Purchase Orders</h3>
                <div className="form-group">
                  <FormLabel
                    label="View"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.jms.purchaseOrders.View)}
                  />{' '}
                  {this.props.renderSelector(
                    permissions.jms.purchaseOrders.View,
                    jmsPurchaseOrdersView,
                    (val) => this.onChangeJmsModulePermissionView(val, 'purchaseorders'),
                    this.props.getPermissionInfoForDropdownOptions(jmsViewMenu, permissions.jms.purchaseOrders.View),
                  )}
                </div>
              </div>
            )}
            {jmsFeatureInfo.canSeePurchaseOrder && jmsPurchaseOrdersView && jmsPurchaseOrdersView !== 'none' && (
              <div>
                <div className="form-group">
                  <FormLabel
                    label="Edit"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.jms.purchaseOrders.Edit)}
                  />{' '}
                  {this.props.renderSelector(
                    permissions.jms.purchaseOrders.Edit,
                    this.props.stateData.jmsPurchaseOrdersEdit,
                    (val) => this.onChangeJmsPermission({ jmsPurchaseOrdersEdit: val.value }),
                    this.props.getPermissionInfoForDropdownOptions(
                      jmsPurchaseOrdersSubMenu,
                      permissions.jms.purchaseOrders.Edit,
                    ),
                  )}
                </div>
                {this.props.renderCheckbox(
                  permissions.jms.purchaseOrders.Create,
                  'Create',
                  !this.props.stateData.jmsPurchaseOrdersCreate ? false : this.props.stateData.jmsPurchaseOrdersCreate,
                  () =>
                    this.onChangeJmsPermission({
                      jmsPurchaseOrdersCreate: !this.props.stateData.jmsPurchaseOrdersCreate,
                    }),
                )}
                <div className="form-group">
                  <FormLabel
                    label="Delete"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.jms.purchaseOrders.Delete)}
                  />{' '}
                  {this.props.renderSelector(
                    permissions.jms.purchaseOrders.Delete,
                    this.props.stateData.jmsPurchaseOrdersDelete,
                    (val) => this.onChangeJmsPermission({ jmsPurchaseOrdersDelete: val.value }),
                    this.props.getPermissionInfoForDropdownOptions(
                      jmsPurchaseOrdersSubMenu,
                      permissions.jms.purchaseOrders.Delete,
                    ),
                  )}
                </div>
                {this.props.renderCheckbox(
                  permissions.jms.purchaseOrders.POValueExceedApprover,
                  'Approve PO Value Exceed',
                  !this.props.stateData.jmsPurchaseOrdersValueExceedApprover
                    ? false
                    : this.props.stateData.jmsPurchaseOrdersValueExceedApprover,
                  () =>
                    this.onChangeJmsPermission({
                      jmsPurchaseOrdersValueExceedApprover: !this.props.stateData.jmsPurchaseOrdersValueExceedApprover,
                    }),
                )}

                {jmsFeatureInfo.canSeeInvoices &&
                  this.props.renderCheckbox(
                    permissions.jms.poPurchaseInvoice.View,
                    'View Purchase Invoice',
                    !this.props.stateData.jmsPOPurchaseInvoiceView
                      ? false
                      : this.props.stateData.jmsPOPurchaseInvoiceView,
                    () =>
                      this.onChangeJmsPermissionWithFeature(
                        {
                          jmsPOPurchaseInvoiceView: !this.props.stateData.jmsPOPurchaseInvoiceView,
                        },
                        jmsFeatureInfo,
                      ),
                  )}
                {jmsFeatureInfo.canSeeInvoices &&
                  this.props.renderCheckbox(
                    permissions.jms.poPurchaseInvoice.Create,
                    'Create Purchase Invoice',
                    !this.props.stateData.jmsPOPurchaseInvoiceCreate
                      ? false
                      : this.props.stateData.jmsPOPurchaseInvoiceCreate,
                    () =>
                      this.onChangeJmsPermission({
                        jmsPOPurchaseInvoiceCreate: !this.props.stateData.jmsPOPurchaseInvoiceCreate,
                      }),
                  )}
                {jmsFeatureInfo.canSeeInvoices &&
                  this.props.renderCheckbox(
                    permissions.jms.poPurchaseInvoice.Edit,
                    'Edit Purchase Invoice',
                    !this.props.stateData.jmsPOPurchaseInvoiceEdit
                      ? false
                      : this.props.stateData.jmsPOPurchaseInvoiceEdit,
                    () =>
                      this.onChangeJmsPermission({
                        jmsPOPurchaseInvoiceEdit: !this.props.stateData.jmsPOPurchaseInvoiceEdit,
                      }),
                  )}
                {jmsFeatureInfo.canSeeInvoices &&
                  this.props.renderCheckbox(
                    permissions.jms.poPurchaseInvoice.Delete,
                    'Delete Purchase Invoice',
                    !this.props.stateData.jmsPOPurchaseInvoiceDelete
                      ? false
                      : this.props.stateData.jmsPOPurchaseInvoiceDelete,
                    () =>
                      this.onChangeJmsPermission({
                        jmsPOPurchaseInvoiceDelete: !this.props.stateData.jmsPOPurchaseInvoiceDelete,
                      }),
                  )}
              </div>
            )}
            {jmsFeatureInfo.canSeeCompanies && (
              <div>
                <h3>Companies</h3>
                <div className="form-group">
                  <FormLabel
                    label="View"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.jms.companies.View)}
                  />{' '}
                  {this.props.renderSelector(
                    permissions.jms.companies.View,
                    jmsCompaniesView,
                    (val) => this.onChangeJmsModulePermissionView(val, 'companies'),
                    this.props.getPermissionInfoForDropdownOptions(jmsViewMenu, permissions.jms.companies.View),
                  )}
                </div>
              </div>
            )}
            {jmsFeatureInfo.canSeeCompanies && jmsCompaniesView && jmsCompaniesView !== 'none' && (
              <div>
                <div className="form-group">
                  <FormLabel
                    label="Edit"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.jms.companies.Edit)}
                  />{' '}
                  {this.props.renderSelector(
                    permissions.jms.companies.Edit,
                    this.props.stateData.jmsCompaniesEdit,
                    (val) => this.onChangeJmsPermission({ jmsCompaniesEdit: val.value }),
                    this.props.getPermissionInfoForDropdownOptions(jmsCompaniesSubMenu, permissions.jms.companies.Edit),
                  )}
                </div>
                {this.props.renderCheckbox(
                  permissions.jms.companies.Create,
                  'Create',
                  !this.props.stateData.jmsCompaniesCreate ? false : this.props.stateData.jmsCompaniesCreate,
                  () =>
                    this.onChangeJmsPermission({
                      jmsCompaniesCreate: !this.props.stateData.jmsCompaniesCreate,
                    }),
                )}
                <div className="form-group">
                  <FormLabel
                    label="Delete"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.jms.companies.Delete)}
                  />{' '}
                  {this.props.renderSelector(
                    permissions.jms.companies.Delete,
                    this.props.stateData.jmsCompaniesDelete,
                    (val) => this.onChangeJmsPermission({ jmsCompaniesDelete: val.value }),
                    this.props.getPermissionInfoForDropdownOptions(
                      jmsCompaniesSubMenu,
                      permissions.jms.companies.Delete,
                    ),
                  )}
                </div>

                {this.props.renderCheckbox(
                  permissions.jms.companies.LinkRequest,
                  'Send Link Request',
                  !this.props.stateData.jmsCompaniesLinkRequest ? false : this.props.stateData.jmsCompaniesLinkRequest,
                  () =>
                    this.onChangeJmsPermission({
                      jmsCompaniesLinkRequest: !this.props.stateData.jmsCompaniesLinkRequest,
                    }),
                )}
                {this.props.renderCheckbox(
                  permissions.jms.companies.LinkRespond,
                  'Accept Link Request',
                  !this.props.stateData.jmsCompaniesLinkRespond ? false : this.props.stateData.jmsCompaniesLinkRespond,
                  () =>
                    this.onChangeJmsPermission({
                      jmsCompaniesLinkRespond: !this.props.stateData.jmsCompaniesLinkRespond,
                    }),
                )}
              </div>
            )}

            {jmsFeatureInfo.canSeeJobVisit && (
              <div>
                <h3>Job Visits</h3>
                <div className="form-group">
                  <FormLabel label="View" isShort />{' '}
                  {this.props.renderSelector(
                    permissions.jms.jobVisits.View,
                    jmsJobVisitsView,
                    (val) => this.onChangeJmsModulePermissionView(val, 'job-visits'),
                    jmsViewMenu,
                  )}
                </div>
              </div>
            )}
            {jmsFeatureInfo.canSeeJobVisit && jmsJobVisitsView && jmsJobVisitsView !== 'none' && (
              <div>
                <div className="form-group">
                  <FormLabel label="Edit" isShort />{' '}
                  {this.props.renderSelector(
                    permissions.jms.jobVisits.Edit,
                    this.props.stateData.jmsJobVisitsEdit,
                    (val) => this.onChangeJmsPermission({ jmsJobVisitsEdit: val.value }),
                    jmsJobVisitsSubMenu,
                  )}
                </div>
                {this.props.renderCheckbox(
                  permissions.jms.jobVisits.Create,
                  'Create',
                  !this.props.stateData.jmsJobVisitsCreate ? false : this.props.stateData.jmsJobVisitsCreate,
                  () =>
                    this.onChangeJmsPermission({
                      jmsJobVisitsCreate: !this.props.stateData.jmsJobVisitsCreate,
                    }),
                )}
                <div className="form-group">
                  <FormLabel label="Delete" isShort />{' '}
                  {this.props.renderSelector(
                    permissions.jms.jobVisits.Delete,
                    this.props.stateData.jmsJobVisitsDelete,
                    (val) => this.onChangeJmsPermission({ jmsJobVisitsDelete: val.value }),
                    jmsJobVisitsSubMenu,
                  )}
                </div>
              </div>
            )}
            {jmsFeatureInfo.canSeeJobVisitTimelog && jmsJobVisitsView && jmsJobVisitsView !== 'none' && (
              <div>
                <h3>Job Visit Timelog</h3>
                <div className="form-group">
                  <FormLabel label="View" isShort />{' '}
                  {this.props.renderSelector(
                    permissions.jms.jobVisitsTimeLog.View,
                    jmsJobVisitsTimeLogView,
                    (val) => this.onChangeJmsModulePermissionView(val, 'job-visits-timelog'),
                    jmsViewMenu,
                  )}
                </div>
                {jmsJobVisitsTimeLogView && jmsJobVisitsTimeLogView !== 'none' && (
                  <div>
                    <div className="form-group">
                      <FormLabel label="Edit" isShort />{' '}
                      {this.props.renderSelector(
                        permissions.jms.jobVisitsTimeLog.Edit,
                        this.props.stateData.jmsJobVisitsTimeLogEdit,
                        (val) => this.onChangeJmsPermission({ jmsJobVisitsTimeLogEdit: val.value }),
                        jmsJobVisitsTimeLogSubMenu,
                      )}
                    </div>
                    {this.props.renderCheckbox(
                      permissions.jms.jobVisitsTimeLog.Create,
                      'Create',
                      !this.props.stateData.jmsJobVisitsTimeLogCreate
                        ? false
                        : this.props.stateData.jmsJobVisitsTimeLogCreate,
                      () =>
                        this.onChangeJmsPermission({
                          jmsJobVisitsTimeLogCreate: !this.props.stateData.jmsJobVisitsTimeLogCreate,
                        }),
                    )}
                    {this.props.renderCheckbox(
                      permissions.jms.jobVisitsTimeLog.Approve,
                      'Approve',
                      !this.props.stateData.jmsJobVisitsTimeLogApprove
                        ? false
                        : this.props.stateData.jmsJobVisitsTimeLogApprove,
                      () =>
                        this.onChangeJmsPermission({
                          jmsJobVisitsTimeLogApprove: !this.props.stateData.jmsJobVisitsTimeLogApprove,
                        }),
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    subscription: { entitledApplicationFeatureList },
    user,
  } = state;

  return {
    user,
    entitledApplicationFeatureList,
  };
};

export default connect(mapStateToProps)(JMSRolePermissionsFields);
