import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'redux-form';
import { FormCheckbox, SelectDropdown } from '../form/parts';

import Tabs from './index';
import GeneralRolePermissionsFields from '../form/GeneralRolePermissionsFields';
import RolePermissionsFields from '../form/RolePermissionsFields';
import JMSRolePermissionsFields from '../form/JMSRolePermissionsFields';
import ALPRolePermissionsFields from '../form/ALPRolePermissionsFields';
// import AddOnsRolePermissionsFields from '../form/AddOnsRolePermissionsFields';

const RolePermissionTabs = ({
  t,
  url,
  store,
  handlePermisssionUpdate,
  permissionsListData,
  onChangeJmsModulePermissionView,
  stateData,
  setPermission,
  onChangeCafmModulePermissionView,
  selectedPermissions,
  onChangeAlpModulePermissionView,
  onChangeAddOnsModulePermissionView,
}) => {
  const getPermissionInfoByKey = (key) => {
    if (permissionsListData && permissionsListData.length > 0 && key && key.trim() !== '') {
      const keyIndex = _.findIndex(permissionsListData, { name: key });
      const keyInfo =
        permissionsListData[keyIndex] && permissionsListData[keyIndex].label
          ? permissionsListData[keyIndex].label
          : ' ';
      return keyInfo;
    }
    return ' ';
  };

  const getPermissionInfoForDropdownOptions = (listData, permission) => {
    const newData = Object.assign(
      [],
      listData.map((data) => {
        data.tooltipText = 'no access';
        if (data.key === 'own') {
          data.tooltipText = getPermissionInfoByKey(`${permission}.own`);
        } else if (data.key === 'all') {
          data.tooltipText = getPermissionInfoByKey(`${permission}.all`);
        } else if (data.key === 'manager') {
          data.tooltipText = getPermissionInfoByKey(`${permission}.manager`);
        } else if (data.key === 'mover') {
          data.tooltipText = getPermissionInfoByKey(`${permission}.mover`);
        }
        return { ...data };
      }),
    );
    return newData;
  };

  const renderCheckbox = (key, label, state, onChange) => (
    <Field
      key={key}
      label={t(`${label}`)}
      component={FormCheckbox}
      name={key}
      type="checkbox"
      isShort
      input={{ value: state, onChange }}
      tooltipText={getPermissionInfoByKey(key)}
    />
  );

  const renderSelector = (key, state, onChange, options) => (
    <Field
      name={key}
      component={SelectDropdown}
      drpName={key}
      options={options}
      _optionLabel="value"
      _optionValue="key"
      isTooltipShow
      placeholder={t('Choose a field')}
      onChange={onChange}
      defaultValue={state}
      width="50%"
      _className="col-sm-9"
    />
  );

  const components = [
    {
      header: 'General',
      content: [
        <GeneralRolePermissionsFields
          t={t}
          handlePermisssionUpdate={handlePermisssionUpdate}
          permissionsListData={permissionsListData}
          onChangeCafmModulePermissionView={onChangeCafmModulePermissionView}
          stateData={stateData}
          setPermission={setPermission}
          selectedPermissions={selectedPermissions}
          renderCheckbox={renderCheckbox}
          renderSelector={renderSelector}
          getPermissionInfoByKey={getPermissionInfoByKey}
          getPermissionInfoForDropdownOptions={getPermissionInfoForDropdownOptions}
        />,
      ],
    },
    {
      header: 'CAFM',
      content: [
        <RolePermissionsFields
          t={t}
          handlePermisssionUpdate={handlePermisssionUpdate}
          permissionsListData={permissionsListData}
          onChangeCafmModulePermissionView={onChangeCafmModulePermissionView}
          stateData={stateData}
          setPermission={setPermission}
          selectedPermissions={selectedPermissions}
          renderCheckbox={renderCheckbox}
          renderSelector={renderSelector}
          getPermissionInfoByKey={getPermissionInfoByKey}
          getPermissionInfoForDropdownOptions={getPermissionInfoForDropdownOptions}
        />,
      ],
    },
    {
      header: 'JMS',
      content: [
        <JMSRolePermissionsFields
          t={t}
          permissionsListData={permissionsListData}
          handlePermisssionUpdate={handlePermisssionUpdate}
          onChangeJmsModulePermissionView={onChangeJmsModulePermissionView}
          stateData={stateData}
          onChangeJmsPermission={setPermission}
          selectedPermissions={selectedPermissions}
          renderCheckbox={renderCheckbox}
          renderSelector={renderSelector}
          getPermissionInfoByKey={getPermissionInfoByKey}
          getPermissionInfoForDropdownOptions={getPermissionInfoForDropdownOptions}
          onChangeAddOnsModulePermissionView={onChangeAddOnsModulePermissionView}
        />,
      ],
    },
    {
      header: 'ALP',
      content: [
        <ALPRolePermissionsFields
          t={t}
          permissionsListData={permissionsListData}
          handlePermisssionUpdate={handlePermisssionUpdate}
          onChangeAlpModulePermissionView={onChangeAlpModulePermissionView}
          stateData={stateData}
          onChangeAlpPermission={setPermission}
          selectedPermissions={selectedPermissions}
          renderCheckbox={renderCheckbox}
          renderSelector={renderSelector}
          getPermissionInfoByKey={getPermissionInfoByKey}
          getPermissionInfoForDropdownOptions={getPermissionInfoForDropdownOptions}
        />,
      ],
    },
    // {
    //   header: 'Add Ons',
    //   content: [
    //     <AddOnsRolePermissionsFields
    //       t={t}
    //       permissionsListData={permissionsListData}
    //       handlePermisssionUpdate={handlePermisssionUpdate}
    //       onChangeAddOnsModulePermissionView={onChangeAddOnsModulePermissionView}
    //       stateData={stateData}
    //       onChangeAddOnsPermission={setPermission}
    //       selectedPermissions={selectedPermissions}
    //       renderCheckbox={renderCheckbox}
    //       renderSelector={renderSelector}
    //       getPermissionInfoByKey={getPermissionInfoByKey}
    //       getPermissionInfoForDropdownOptions={getPermissionInfoForDropdownOptions}
    //     />,
    //   ],
    // },
  ];
  return <Tabs components={components} url={url} store={store} t={t} />;
};

RolePermissionTabs.propTypes = {
  t: PropTypes.func.isRequired,
  url: PropTypes.shape().isRequired,
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
  }).isRequired,
};

export default RolePermissionTabs;
