import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { FormInput, ReduxFormSelect } from './parts';

const CreateCategoryForm = ({ onSubmit, categoryGroupNames, t }) => (
  <div className="container-fluid white-bg">
    <div className="col-lg-12">
      <div className="row row-title p-sm">
        <form className="form-horizontal col-sm-12 col-md-10 col-lg-8">
          <div className="row">
            <div className="col-md-12">
              <button className="filter-button" type="submit" onClick={onSubmit}>
                {t('Save')}
              </button>

              <div className="form-group">
                <label className="col-sm-4 control-label">{t('Category group')}</label>

                <div className="col-sm-8">
                  <Field
                    name="group"
                    component={ReduxFormSelect}
                    options={categoryGroupNames}
                    placeholder={t('Choose category group')}
                  />
                </div>
              </div>

              <Field label={t('Name')} component={FormInput} name="name" type="text" placeholder={t('Name')} />

              <Field label={t('Code')} component={FormInput} name="code" type="text" placeholder={t('Code')} />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
);

CreateCategoryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  categoryGroupNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  t: PropTypes.func.isRequired,
};

export default CreateCategoryForm;
