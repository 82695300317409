import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, change } from 'redux-form';
import _ from 'lodash';

import { FormInput, ReduxFormSelect } from './parts';
import { renderCategoryGroups, renderFilteredCategoryGroups } from './CategoryGroupsHandler';

class CreateBuildingBudgetCategoryForm extends Component {
  static propTypes = {
    categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    categoryGroups: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    addCategoryForm: PropTypes.shape().isRequired,
    budget: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    store: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    budget: null,
  };

  constructor(props) {
    super(props);
    const categoryGroups = renderCategoryGroups(props.categoryGroups, props.categories);
    const filteredGroups = this.filterExistingCategories(categoryGroups, props.budget);

    this.state = {
      categoryGroups: filteredGroups,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { categoryGroups, categories, budget } = nextProps;
    if (categoryGroups && budget && categoryGroups.length > 0) {
      const newCategoryGroups = renderCategoryGroups(categoryGroups, categories);
      const filteredGroups = this.filterExistingCategories(newCategoryGroups, budget);
      this.setState({
        categoryGroups: filteredGroups,
      });
    }
    const form = nextProps.addCategoryForm.addBuildingBudgetCategoryForm;
    if (form && form.values && form.values.name) {
      const chosenCategories = [];
      chosenCategories.push(form.values.name);
      const newCategoryGroups = renderFilteredCategoryGroups(categoryGroups, categories, chosenCategories);
      const filteredGroups = this.filterExistingCategories(newCategoryGroups, budget);
      this.setState({
        categoryGroups: filteredGroups,
      });
    }
  }

  filterExistingCategories = (categoryGroups, budget) => {
    if (budget) {
      const existingCategories = budget.BuildingBudgetCategory.map((category) => category.BudgetCategory.name);
      categoryGroups.forEach((group) => {
        const filteredCategories = group.categories.filter((name) => existingCategories.indexOf(name) === -1);
        group.categories = filteredCategories;
      });
    }
    return categoryGroups;
  };

  render() {
    const { onSubmit, budget, categories, store, addCategoryForm, t } = this.props;
    const { categoryGroups } = this.state;
    const categoryNames = categoryGroups.map((group) => group.categories);
    const categoryGroupNames = categoryGroups.map((group) => group.name);
    const chosenGroupName =
      addCategoryForm &&
      addCategoryForm.addBuildingBudgetCategoryForm &&
      addCategoryForm.addBuildingBudgetCategoryForm.values &&
      addCategoryForm.addBuildingBudgetCategoryForm.values.group;
    const categoriesIndex = _.findIndex(categoryGroupNames, (name) => name === chosenGroupName);
    return (
      <div className="container-fluid white-bg">
        <div className="col-lg-12">
          <div className="row row-title p-sm">
            {categoryNames.length > 0 && (
              <form className="form-horizontal col-sm-12 col-md-10 col-lg-8">
                <div className="row">
                  <div className="col-md-12">
                    <button className="filter-button" type="submit" onClick={onSubmit}>
                      {t('Submit')}
                    </button>

                    <div className="form-group">
                      <label className="col-sm-4 control-label">{t('Category group')}</label>

                      <div className="col-sm-8">
                        <Field
                          name="group"
                          component={ReduxFormSelect}
                          onChange={() => {
                            store.dispatch(change('addBuildingBudgetCategoryForm', 'name', null));
                          }}
                          options={categoryGroupNames}
                          placeholder={t('Category group')}
                        />
                      </div>
                    </div>

                    {categoriesIndex !== -1 ? (
                      <div className="form-group">
                        <label className="col-sm-4 control-label">{t('Category')}</label>

                        <div className="col-sm-8">
                          <Field
                            name="name"
                            component={ReduxFormSelect}
                            options={categoryNames[categoriesIndex]}
                            placeholder={t('Choose category')}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="form-group building-budget-category-gap" />
                    )}

                    <Field
                      label={t('Total Amount')}
                      component={FormInput}
                      name="total"
                      type="number"
                      placeholder={t('Total Amount')}
                    />

                    <Field
                      label={t('Planned Amount')}
                      component={FormInput}
                      name="planned"
                      type="number"
                      placeholder={t('Planned Amount')}
                    />

                    <Field
                      label={t('Unplanned Amount')}
                      component={FormInput}
                      name="unplanned"
                      type="number"
                      placeholder={t('Unplanned Amount')}
                    />
                  </div>
                </div>
              </form>
            )}

            {categories.length === 0 && budget && <div>{t('All categories are already in use')}</div>}
          </div>
        </div>
      </div>
    );
  }
}

export default CreateBuildingBudgetCategoryForm;
