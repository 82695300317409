export const renderCategoryGroups = (groups, categories) => {
  const categoryGroups = groups.map((group) => ({
    name: group.name,
    categories: categories
      .filter((category) => category.budgetCategoryGroupId === group.id)
      .map((category) => category.name),
  }));
  categoryGroups.unshift({
    name: 'none',
    categories: categories
      .filter((category) => category.budgetCategoryGroupId === null)
      .map((category) => category.name),
  });
  return categoryGroups;
};

export const renderFilteredCategoryGroups = (groups, categories, chosenCategories) => {
  const categoryGroups = groups.map((group) => ({
    name: group.name,
    categories: categories
      .filter((category) => category.budgetCategoryGroupId === group.id)
      .map((category) => category.name)
      .filter((name) => chosenCategories.indexOf(name) === -1),
  }));
  categoryGroups.unshift({
    name: 'none',
    categories: categories
      .filter((category) => category.budgetCategoryGroupId === null)
      .map((category) => category.name)
      .filter((name) => chosenCategories.indexOf(name) === -1),
  });
  return categoryGroups;
};
