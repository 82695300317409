import React from 'react';
import PropTypes from 'prop-types';
import SitePicturesEdit from '../../containers/decorate/SitePicturesEdit';
import { SiteEntryView } from '../cafm/admin';

const CreateBuildingForm = ({ onSubmit, t, siteTypeOptions }) => (
  <div className="container-fluid white-bg">
    <div className="col-lg-12">
      <div className="row row-title p-sm">
        <form className="form-horizontal col-sm-12 col-md-10 col-lg-8">
          <div className="row">
            <div className="col-md-8">
              <SiteEntryView
                t={t}
                args={{
                  siteTypeList: siteTypeOptions,
                }}
              />
              <button className="filter-button form-button" type="submit" onClick={onSubmit}>
                {t('Submit')}
              </button>
            </div>
            <div className="col-md-4">
              <SitePicturesEdit t={t} />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
);

CreateBuildingForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateBuildingForm;
