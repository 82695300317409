import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { FormInput, ReduxFormSelect } from './parts';

const AMOUNT_TYPES = ['amount', 'percentage'];

const CreateThresholdForm = ({ onSubmit, t }) => (
  <div className="container-fluid white-bg">
    <div className="col-lg-12">
      <div className="row row-title p-sm">
        <form className="form-horizontal col-sm-12 col-md-10 col-lg-8">
          <div className="row">
            <div className="col-md-12">
              <button className="filter-button" type="submit" onClick={onSubmit}>
                {t('Submit')}
              </button>

              <div className="form-group">
                <label className="col-sm-4 control-label">{t('Amount type')}</label>

                <div className="col-sm-8">
                  <Field
                    name="amountType"
                    component={ReduxFormSelect}
                    options={AMOUNT_TYPES}
                    placeholder={t('Choose amount type')}
                  />
                </div>
              </div>

              <Field
                label={t('Amount size')}
                component={FormInput}
                name="amountSize"
                type="text"
                placeholder={t('Enter amount size')}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
);

CreateThresholdForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default CreateThresholdForm;
