import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { permissions } from '../../constants/permissions';
import commonFunction from '../../lib/commonFunction';
import { FormLabel } from './parts';

class ALPRolePermissionsFields extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    permissionsListData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    selectedPermissions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    handleAlpPermisssionUpdate: PropTypes.func,
    onChangeAlpModulePermissionView: PropTypes.func,
    stateData: PropTypes.arrayOf(PropTypes.shape()),
    onChangeAlpPermission: PropTypes.func,
    renderCheckbox: PropTypes.func.isRequired,
    renderSelector: PropTypes.func.isRequired,
    getPermissionInfoByKey: PropTypes.func,
    getPermissionInfoForDropdownOptions: PropTypes.func,
    entitledApplicationFeatureList: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    handleAlpPermisssionUpdate: undefined,
    permissionsListData: [],
    selectedPermissions: [],
    onChangeAlpModulePermissionView: undefined,
    stateData: [],
    onChangeAlpPermission: undefined,
    getPermissionInfoByKey: undefined,
    getPermissionInfoForDropdownOptions: undefined,
    entitledApplicationFeatureList: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      ...props.stateData,
    };
  }

  onChangeAlpModulePermissionView = (value, flag) => {
    value = typeof value === 'object' ? value.value : value;
    this.props.onChangeAlpModulePermissionView(value, flag);
  };

  onChangeAlpPermission = (value) => {
    this.props.onChangeAlpPermission(value, 'alp');
  };

  render() {
    const { t, entitledApplicationFeatureList } = this.props;
    let {
      alpAccess,
      alpAlltimeoffView,
      alpSettingsView,
      alpSettingsPeriodsView,
      alpAllowancesView,
      alpAllowancesAdjustmentsView,
      alpEmployeesView,
      alpTeamsView,
    } = this.props.stateData;

    let alpFeatureInfo = {};
    if (alpAccess) {
      alpFeatureInfo = commonFunction.getEntitledAccountApplicationFeature({
        application: 'alp',
        entitledApplicationFeatureList,
      });
    }

    alpAlltimeoffView = !alpAlltimeoffView ? 'none' : alpAlltimeoffView;
    alpSettingsView = !alpSettingsView ? 'none' : alpSettingsView;
    alpSettingsPeriodsView = !alpSettingsPeriodsView ? 'none' : alpSettingsPeriodsView;
    alpAllowancesView = !alpAllowancesView ? 'none' : alpAllowancesView;
    alpAllowancesAdjustmentsView = !alpAllowancesAdjustmentsView ? 'none' : alpAllowancesAdjustmentsView;
    alpEmployeesView = !alpEmployeesView ? 'none' : alpEmployeesView;
    alpTeamsView = !alpTeamsView ? 'none' : alpTeamsView;

    const memberMinMenu = [
      { key: 'none', value: 'no access' },
      { key: 'own', value: 'access to assigned only' },
    ];
    const memberMinMenuForAlltime = [
      { key: 'none', value: 'no access' },
      { key: 'own', value: 'access to assigned and supervised' },
    ];
    const memberFullMenu = [...memberMinMenu, { key: 'all', value: 'access to all' }];
    const memberFullMenuForAlltime = [...memberMinMenuForAlltime, { key: 'all', value: 'access to all' }];

    const alpViewMenu = memberFullMenuForAlltime;
    const alpAlltimeoffSubMenu = alpAlltimeoffView === 'own' ? memberMinMenu : memberFullMenu;
    const alpSettingsSubMenu = alpSettingsView === 'own' ? memberMinMenu : memberFullMenu;
    const alpSettingsperiodsSubMenu = alpSettingsPeriodsView === 'own' ? memberMinMenu : memberFullMenu;
    const alpAllowancesSubMenu = alpAllowancesView === 'own' ? memberMinMenu : memberFullMenu;
    const alpAllowancesAdjustmentsSubMenu = alpAllowancesAdjustmentsView === 'own' ? memberMinMenu : memberFullMenu;
    const alpEmployeesSubMenu = alpEmployeesView === 'own' ? memberMinMenu : memberFullMenu;
    const alpTeamsSubMenu = alpTeamsView === 'own' ? memberMinMenu : memberFullMenu;
    return (
      <div className="p-sm">
        {!alpAccess && (
          <div className="form-group">
            <h3>Please grant ALP Access to set permissions</h3>
          </div>
        )}
        {alpAccess && alpFeatureInfo.canSeeTimeOff && (
          <div className="form-group">
            <h3>Time Off</h3>
            <FormLabel
              label="View"
              isShort
              tooltipText={this.props.getPermissionInfoByKey(permissions.alp.alltimeoff.View)}
            />
            {this.props.renderSelector(
              permissions.alp.alltimeoff.View,
              alpAlltimeoffView,
              (val) => this.onChangeAlpModulePermissionView(val, 'alltimeoff'),
              this.props.getPermissionInfoForDropdownOptions(alpViewMenu, permissions.alp.alltimeoff.View),
            )}
          </div>
        )}
        {alpAccess && alpFeatureInfo.canSeeTimeOff && alpAlltimeoffView && alpAlltimeoffView !== 'none' && (
          <div>
            <div className="form-group">
              <FormLabel
                label="Mark as Paid"
                isShort
                tooltipText={this.props.getPermissionInfoByKey(permissions.alp.alltimeoff.Edit)}
              />
              {this.props.renderSelector(
                permissions.alp.alltimeoff.Edit,
                this.props.stateData.alpAlltimeoffEdit,
                (val) => this.onChangeAlpPermission({ alpAlltimeoffEdit: val.value }),
                this.props.getPermissionInfoForDropdownOptions(alpAlltimeoffSubMenu, permissions.alp.alltimeoff.Edit),
              )}
            </div>
            <div className="form-group">
              <FormLabel
                label="Create"
                isShort
                tooltipText={this.props.getPermissionInfoByKey(permissions.alp.alltimeoff.Create)}
              />
              {this.props.renderSelector(
                permissions.alp.alltimeoff.Create,
                this.props.stateData.alpAlltimeoffCreate,
                (val) => this.onChangeAlpPermission({ alpAlltimeoffCreate: val.value }),
                this.props.getPermissionInfoForDropdownOptions(alpAlltimeoffSubMenu, permissions.alp.alltimeoff.Create),
              )}
            </div>
            <div className="form-group">
              <FormLabel
                label="Delete"
                isShort
                tooltipText={this.props.getPermissionInfoByKey(permissions.alp.alltimeoff.Delete)}
              />
              {this.props.renderSelector(
                permissions.alp.alltimeoff.Delete,
                this.props.stateData.alpAlltimeoffDelete,
                (val) => this.onChangeAlpPermission({ alpAlltimeoffDelete: val.value }),
                this.props.getPermissionInfoForDropdownOptions(alpAlltimeoffSubMenu, permissions.alp.alltimeoff.Delete),
              )}
            </div>
            {this.props.renderCheckbox(
              permissions.alp.alltimeoff.Approve,
              'Approve',
              !this.props.stateData.alpAlltimeoffApprove ? false : this.props.stateData.alpAlltimeoffApprove,
              () =>
                this.onChangeAlpPermission({
                  alpAlltimeoffApprove: !this.props.stateData.alpAlltimeoffApprove,
                }),
            )}
            {this.props.renderCheckbox(
              permissions.alp.alltimeoff.ManualApproval,
              'Request Manual Approval',
              !this.props.stateData.alpAlltimeoffManualApproval
                ? false
                : this.props.stateData.alpAlltimeoffManualApproval,
              () =>
                this.onChangeAlpPermission({
                  alpAlltimeoffManualApproval: !this.props.stateData.alpAlltimeoffManualApproval,
                }),
            )}
            {this.props.renderCheckbox(
              permissions.alp.alltimeoff.AdminEditTimeOffDetail,
              'Edit Time Off As Admin',
              !this.props.stateData.alpAdminAllowEditTimeOffDetails
                ? false
                : this.props.stateData.alpAdminAllowEditTimeOffDetails,
              () =>
                this.onChangeAlpPermission({
                  alpAdminAllowEditTimeOffDetails: !this.props.stateData.alpAdminAllowEditTimeOffDetails,
                }),
            )}
          </div>
        )}
        {alpAccess && alpFeatureInfo.canSeeSetting && (
          <div className="form-group">
            <h3>Settings</h3>
            {this.props.renderCheckbox(
              permissions.alp.settings.Edit,
              'Edit',
              !this.props.stateData.alpSettingsEdit ? false : this.props.stateData.alpSettingsEdit,
              () =>
                this.onChangeAlpPermission({
                  alpSettingsEdit: !this.props.stateData.alpSettingsEdit,
                }),
            )}
          </div>
        )}

        {alpAccess && alpFeatureInfo.canSeeEmployee && (
          <div className="form-group">
            <h3>Employees</h3>
            <div className="form-group">
              <FormLabel
                label="View"
                isShort
                tooltipText={this.props.getPermissionInfoByKey(permissions.alp.employees.View)}
              />{' '}
              {this.props.renderSelector(
                permissions.alp.employees.View,
                alpEmployeesView,
                (val) => this.onChangeAlpModulePermissionView(val, 'hremployee'),
                this.props.getPermissionInfoForDropdownOptions(alpViewMenu, permissions.alp.employees.View),
              )}
            </div>
            {alpEmployeesView && alpEmployeesView !== 'none' && (
              <div>
                <div className="form-group">
                  <FormLabel
                    label="Edit"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.alp.employees.Edit)}
                  />{' '}
                  {this.props.renderSelector(
                    permissions.alp.employees.Edit,
                    this.props.stateData.alpEmployeesEdit,
                    (val) => this.onChangeAlpPermission({ alpEmployeesEdit: val.value }),
                    this.props.getPermissionInfoForDropdownOptions(alpEmployeesSubMenu, permissions.alp.employees.Edit),
                  )}
                </div>
                {this.props.renderCheckbox(
                  permissions.alp.employees.Create,
                  'Create',
                  !this.props.stateData.alpEmployeesCreate ? false : this.props.stateData.alpEmployeesCreate,
                  () =>
                    this.onChangeAlpPermission({
                      alpEmployeesCreate: !this.props.stateData.alpEmployeesCreate,
                    }),
                )}
                <div className="form-group">
                  <FormLabel
                    label="Delete"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.alp.employees.Delete)}
                  />{' '}
                  {this.props.renderSelector(
                    permissions.alp.employees.Delete,
                    this.props.stateData.alpEmployeesDelete,
                    (val) => this.onChangeAlpPermission({ alpEmployeesDelete: val.value }),
                    this.props.getPermissionInfoForDropdownOptions(
                      alpEmployeesSubMenu,
                      permissions.alp.employees.Delete,
                    ),
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {alpAccess && alpFeatureInfo.canSeeTeam && (
          <div className="form-group">
            <h3>Teams</h3>
            <div className="form-group">
              <FormLabel
                label="View"
                isShort
                tooltipText={this.props.getPermissionInfoByKey(permissions.alp.teams.View)}
              />{' '}
              {this.props.renderSelector(
                permissions.alp.teams.View,
                alpTeamsView,
                (val) => this.onChangeAlpModulePermissionView(val, 'hrteams'),
                this.props.getPermissionInfoForDropdownOptions(alpViewMenu, permissions.alp.teams.View),
              )}
            </div>
            {alpTeamsView && alpTeamsView !== 'none' && (
              <div>
                <div className="form-group">
                  <FormLabel
                    label="Edit"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.alp.teams.Edit)}
                  />{' '}
                  {this.props.renderSelector(
                    permissions.alp.teams.Edit,
                    this.props.stateData.alpTeamsEdit,
                    (val) => this.onChangeAlpPermission({ alpTeamsEdit: val.value }),
                    this.props.getPermissionInfoForDropdownOptions(alpTeamsSubMenu, permissions.alp.teams.Edit),
                  )}
                </div>
                {this.props.renderCheckbox(
                  permissions.alp.teams.Create,
                  'Create',
                  !this.props.stateData.alpTeamsCreate ? false : this.props.stateData.alpTeamsCreate,
                  () =>
                    this.onChangeAlpPermission({
                      alpTeamsCreate: !this.props.stateData.alpTeamsCreate,
                    }),
                )}
                <div className="form-group">
                  <FormLabel
                    label="Delete"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.alp.teams.Delete)}
                  />{' '}
                  {this.props.renderSelector(
                    permissions.alp.teams.Delete,
                    this.props.stateData.alpTeamsDelete,
                    (val) => this.onChangeAlpPermission({ alpTeamsDelete: val.value }),
                    this.props.getPermissionInfoForDropdownOptions(alpTeamsSubMenu, permissions.alp.teams.Delete),
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    subscription: { entitledApplicationFeatureList },
  } = state;

  return {
    entitledApplicationFeatureList,
  };
};

export default connect(mapStateToProps)(ALPRolePermissionsFields);
