import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { permissions } from '../../constants/permissions';
import commonFunction from '../../lib/commonFunction';
import { FormLabel } from './parts';

class RolePermissionsFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.stateData,
    };
  }

  onChangeCafmModulePermissionView = (value, flag) => {
    value = typeof value === 'object' ? value.value : value;
    this.props.onChangeCafmModulePermissionView(value, flag);
  };

  setPermission = (value) => {
    this.props.setPermission(value, 'cafm');
  };

  render() {
    const { t, entitledApplicationFeatureList } = this.props;

    let {
      cafmAccess,
      cafmAdmin,
      cafmAllSitesView,
      cafmAllSitesEdit,
      cafmAllSitesDelete,
      cafmMySitesView,
      cafmUsersView,
      cafmAddressesView,
      cafmNotesView,
      cafmContractorsView,
      cafmNotificationsView,
      cafmLocationsView,
      cafmScheduleView,
      cafmAssetsView,
      cafmAssetRegisterView,
      cafmCalendarView,
      cafmTasksView,
      cafmHelpdeskView,
      cafmEngineerView,
      cafmDocumentsView,
      cafmCompaniesView,
      cafmInductionView,
      cafmRiskView,
      cafmAssetSurveyView,
      cafmReactiveView,
      cafmPostroomView,
    } = this.props.stateData;

    let cafmFeatureInfo = {};
    if (cafmAccess) {
      cafmFeatureInfo = commonFunction.getEntitledAccountApplicationFeature({
        application: 'cafm',
        entitledApplicationFeatureList,
      });
    }

    cafmAllSitesView = !cafmAllSitesView ? false : cafmAllSitesView;
    cafmAllSitesEdit = !cafmAllSitesEdit ? false : cafmAllSitesEdit;
    cafmAllSitesDelete = !cafmAllSitesDelete ? false : cafmAllSitesDelete;
    cafmMySitesView = !cafmMySitesView ? 'none' : cafmMySitesView;
    cafmAddressesView = !cafmAddressesView ? 'none' : cafmAddressesView;
    cafmNotesView = !cafmNotesView ? 'none' : cafmNotesView;
    cafmNotificationsView = !cafmNotificationsView ? 'none' : cafmNotificationsView;
    cafmLocationsView = !cafmLocationsView ? 'none' : cafmLocationsView;
    cafmScheduleView = !cafmScheduleView ? 'none' : cafmScheduleView;
    cafmAssetsView = !cafmAssetsView ? 'none' : cafmAssetsView;
    cafmReactiveView = !cafmReactiveView ? 'none' : cafmReactiveView;
    cafmPostroomView = !cafmPostroomView ? 'none' : cafmPostroomView;
    cafmAssetRegisterView = !cafmAssetRegisterView ? 'none' : cafmAssetRegisterView;
    cafmCalendarView = !cafmCalendarView ? 'none' : cafmCalendarView;
    cafmTasksView = !cafmTasksView ? 'none' : cafmTasksView;
    cafmHelpdeskView = !cafmHelpdeskView ? 'none' : cafmHelpdeskView;
    cafmEngineerView = !cafmEngineerView ? 'none' : cafmEngineerView;
    cafmDocumentsView = !cafmDocumentsView ? 'none' : cafmDocumentsView;

    cafmCompaniesView = !cafmCompaniesView ? 'none' : cafmCompaniesView;
    cafmInductionView = !cafmInductionView ? 'none' : cafmInductionView;
    cafmRiskView = !cafmRiskView ? 'none' : cafmRiskView;

    const memberMinMenu = [
      { key: 'none', value: 'no access' },
      { key: 'own', value: 'access to assigned only' },
    ];
    const memberFullMenu = [...memberMinMenu, { key: 'all', value: 'access to all' }];

    const cafmMySitesViewMenu = cafmAllSitesView !== true ? memberMinMenu : memberFullMenu;
    const cafmMySitesEditMenu = cafmAllSitesEdit !== true ? memberMinMenu : memberFullMenu;
    const cafmMySitesDeleteMenu = cafmAllSitesDelete !== true ? memberMinMenu : memberFullMenu;

    const cafmSiteSubMenu = cafmMySitesView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmMySiteSubMenu = cafmMySitesView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmUsersSubMenu = cafmUsersView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmAddressesMenu = cafmAddressesView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmNotesMenu = cafmNotesView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmContractorsMenu = cafmContractorsView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmNotificationsMenu = cafmNotificationsView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmLocationsMenu = cafmLocationsView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmScheduleMenu = cafmScheduleView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmAssetRegisterMenu = cafmAssetsView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmAssetsMenu = cafmAssetsView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmReactiveMenu = cafmReactiveView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmPostroomMenu = cafmPostroomView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmCalendarMenu = cafmCalendarView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmTasksMenu = cafmTasksView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmHelpdeskMenu = cafmHelpdeskView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmEngineerMenu = cafmEngineerView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmDocumentsMenu = cafmDocumentsView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmCompaniesMenu = cafmCompaniesView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmInductionMenu = cafmInductionView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmRiskMenu = cafmRiskView === 'own' ? memberMinMenu : memberFullMenu;
    const cafmAssetSurveyMenu = cafmAssetSurveyView === 'own' ? memberMinMenu : memberFullMenu;

    return (
      <div className="p-sm">
        {!cafmAccess && (
          <div className="form-group">
            <h3>Please grant CAFM Access to set permissions</h3>
          </div>
        )}
        {cafmAccess && (
          <div>
            <h2>All Sites</h2>
            {this.props.renderCheckbox(
              permissions.sites.ViewAll,
              'View All',
              this.props.stateData.cafmAllSitesView,
              (val) => this.onChangeCafmModulePermissionView(val, 'allsitesview'),
            )}
            {cafmAllSitesView === true && (
              <div>
                {this.props.renderCheckbox(
                  permissions.site.Create,
                  'Create',
                  this.props.stateData.cafmMySitesCreate,
                  () =>
                    this.props.setPermission({ cafmMySitesCreate: !this.props.stateData.cafmMySitesCreate }, 'cafm'),
                )}
                {this.props.renderCheckbox(
                  permissions.sites.EditAll,
                  'Edit All',
                  this.props.stateData.cafmAllSitesEdit,
                  (val) => this.onChangeCafmModulePermissionView(val, 'allsitesedit'),
                )}
                {this.props.renderCheckbox(
                  permissions.sites.DeleteAll,
                  'Delete All',
                  this.props.stateData.cafmAllSitesDelete,
                  (val) => this.onChangeCafmModulePermissionView(val, 'allsitesdelete'),
                )}
              </div>
            )}
          </div>
        )}
        {cafmAccess && cafmAllSitesView !== true && (
          <div>
            <h2>My Own Sites</h2>
            <div className="form-group">
              <FormLabel label="View" isShort tooltipText={this.props.getPermissionInfoByKey(permissions.site.View)} />
              {this.props.renderSelector(
                permissions.site.View,
                cafmMySitesView,
                (val) => this.onChangeCafmModulePermissionView(val, 'mysitesview'),
                this.props.getPermissionInfoForDropdownOptions(cafmMySitesViewMenu, permissions.site.View),
              )}
            </div>
          </div>
        )}
        {cafmMySitesView !== 'none' && (
          <div>
            <div>
              {cafmAllSitesView !== true && (
                <div>
                  <div className="form-group">
                    <FormLabel
                      label="Edit"
                      isShort
                      tooltipText={this.props.getPermissionInfoByKey(permissions.site.Edit)}
                    />
                    {this.props.renderSelector(
                      permissions.site.Edit,
                      this.props.stateData.cafmMySitesEdit,
                      (val) => this.onChangeCafmModulePermissionView(val, 'mysitesedit'),
                      this.props.getPermissionInfoForDropdownOptions(cafmMySitesEditMenu, permissions.site.Edit),
                    )}
                  </div>
                  <div className="form-group">
                    <FormLabel
                      label="Delete"
                      isShort
                      tooltipText={this.props.getPermissionInfoByKey(permissions.site.Delete)}
                    />
                    {this.props.renderSelector(
                      permissions.site.Delete,
                      this.props.stateData.cafmMySitesDelete,
                      (val) => this.onChangeCafmModulePermissionView(val, 'mysitesdelete'),
                      this.props.getPermissionInfoForDropdownOptions(cafmMySitesDeleteMenu, permissions.site.Delete),
                    )}
                  </div>
                </div>
              )}
            </div>

            {cafmFeatureInfo.canSeeSiteDashboard && (
              <div>
                <h2>Site Dashboard</h2>
                <h3>Site Users</h3>
                <div className="form-group">
                  <FormLabel
                    label="View"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.site.users.View)}
                  />
                  {this.props.renderSelector(
                    permissions.site.users.View,
                    cafmUsersView,
                    (val) => this.onChangeCafmModulePermissionView(val, 'usersview'),
                    this.props.getPermissionInfoForDropdownOptions(cafmMySiteSubMenu, permissions.site.users.View),
                  )}
                </div>
                {cafmUsersView !== 'none' && (
                  <div>
                    <div className="form-group">
                      <FormLabel
                        label="Add"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.users.Add)}
                      />
                      {this.props.renderSelector(
                        permissions.site.users.Add,
                        this.props.stateData.cafmUsersAdd,
                        (val) => this.props.setPermission({ cafmUsersAdd: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(cafmUsersSubMenu, permissions.site.users.Add),
                      )}
                    </div>
                    <div className="form-group">
                      <FormLabel
                        label="Delete"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.users.Delete)}
                      />
                      {this.props.renderSelector(
                        permissions.site.users.Delete,
                        this.props.stateData.cafmUsersDelete,
                        (val) => this.props.setPermission({ cafmUsersDelete: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(cafmUsersSubMenu, permissions.site.users.Delete),
                      )}
                    </div>
                  </div>
                )}

                <div>
                  <h3>Site Notes</h3>
                  <div className="form-group">
                    <FormLabel
                      label="View"
                      isShort
                      tooltipText={this.props.getPermissionInfoByKey(permissions.site.notes.View)}
                    />
                    {this.props.renderSelector(
                      permissions.site.notes.View,
                      cafmNotesView,
                      (val) => this.onChangeCafmModulePermissionView(val, 'notesview'),
                      this.props.getPermissionInfoForDropdownOptions(cafmMySiteSubMenu, permissions.site.notes.View),
                    )}
                  </div>
                  {cafmNotesView !== 'none' && (
                    <div>
                      <div className="form-group">
                        <FormLabel
                          label="Edit"
                          isShort
                          tooltipText={this.props.getPermissionInfoByKey(permissions.site.notes.Edit)}
                        />
                        {this.props.renderSelector(
                          permissions.site.notes.Edit,
                          this.props.stateData.cafmNotesEdit,
                          (val) => this.props.setPermission({ cafmNotesEdit: val.value }, 'cafm'),
                          this.props.getPermissionInfoForDropdownOptions(cafmNotesMenu, permissions.site.notes.Edit),
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  <h3>Site Addresses</h3>
                  <div className="form-group">
                    <FormLabel
                      label="View"
                      isShort
                      tooltipText={this.props.getPermissionInfoByKey(permissions.site.addresses.View)}
                    />
                    {this.props.renderSelector(
                      permissions.site.addresses.View,
                      cafmAddressesView,
                      (val) => this.onChangeCafmModulePermissionView(val, 'addressview'),
                      this.props.getPermissionInfoForDropdownOptions(
                        cafmMySiteSubMenu,
                        permissions.site.addresses.View,
                      ),
                    )}
                  </div>
                  {cafmAddressesView !== 'none' && (
                    <div>
                      <div className="form-group">
                        <FormLabel
                          label="Edit"
                          isShort
                          tooltipText={this.props.getPermissionInfoByKey(permissions.site.addresses.Edit)}
                        />
                        {this.props.renderSelector(
                          permissions.site.addresses.Edit,
                          this.props.stateData.cafmAddressesEdit,
                          (val) => this.props.setPermission({ cafmAddressesEdit: val.value }, 'cafm'),
                          this.props.getPermissionInfoForDropdownOptions(
                            cafmAddressesMenu,
                            permissions.site.addresses.Edit,
                          ),
                        )}
                      </div>
                      <div className="form-group">
                        <FormLabel
                          label="Create"
                          isShort
                          tooltipText={this.props.getPermissionInfoByKey(permissions.site.addresses.Create)}
                        />
                        {this.props.renderSelector(
                          permissions.site.addresses.Create,
                          this.props.stateData.cafmAddressesCreate,
                          (val) => this.props.setPermission({ cafmAddressesCreate: val.value }, 'cafm'),
                          this.props.getPermissionInfoForDropdownOptions(
                            cafmAddressesMenu,
                            permissions.site.addresses.Create,
                          ),
                        )}
                      </div>
                      <div className="form-group">
                        <FormLabel
                          label="Delete"
                          isShort
                          tooltipText={this.props.getPermissionInfoByKey(permissions.site.addresses.Delete)}
                        />
                        {this.props.renderSelector(
                          permissions.site.addresses.Delete,
                          this.props.stateData.cafmAddressesDelete,
                          (val) => this.props.setPermission({ cafmAddressesDelete: val.value }, 'cafm'),
                          this.props.getPermissionInfoForDropdownOptions(
                            cafmAddressesMenu,
                            permissions.site.addresses.Delete,
                          ),
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  <h3>Site Contractors</h3>
                  <div className="form-group">
                    <FormLabel
                      label="View"
                      isShort
                      tooltipText={this.props.getPermissionInfoByKey(permissions.site.contractors.View)}
                    />
                    {this.props.renderSelector(
                      permissions.site.contractors.View,
                      cafmContractorsView,
                      (val) => this.onChangeCafmModulePermissionView(val, 'contractorsview'),
                      this.props.getPermissionInfoForDropdownOptions(
                        cafmMySiteSubMenu,
                        permissions.site.contractors.View,
                      ),
                    )}
                  </div>
                  {cafmContractorsView !== 'none' && (
                    <div>
                      <div className="form-group">
                        <FormLabel
                          label="Edit"
                          isShort
                          tooltipText={this.props.getPermissionInfoByKey(permissions.site.contractors.Edit)}
                        />
                        {this.props.renderSelector(
                          permissions.site.contractors.Edit,
                          this.props.stateData.cafmContractorsEdit,
                          (val) => this.props.setPermission({ cafmContractorsEdit: val.value }, 'cafm'),
                          this.props.getPermissionInfoForDropdownOptions(
                            cafmContractorsMenu,
                            permissions.site.contractors.Edit,
                          ),
                        )}
                      </div>
                      <div className="form-group">
                        <FormLabel
                          label="Create"
                          isShort
                          tooltipText={this.props.getPermissionInfoByKey(permissions.site.contractors.Create)}
                        />
                        {this.props.renderSelector(
                          permissions.site.contractors.Create,
                          this.props.stateData.cafmContractorsCreate,
                          (val) => this.props.setPermission({ cafmContractorsCreate: val.value }, 'cafm'),
                          this.props.getPermissionInfoForDropdownOptions(
                            cafmContractorsMenu,
                            permissions.site.contractors.Create,
                          ),
                        )}
                      </div>
                      <div className="form-group">
                        <FormLabel
                          label="Delete"
                          isShort
                          tooltipText={this.props.getPermissionInfoByKey(permissions.site.contractors.Delete)}
                        />
                        {this.props.renderSelector(
                          permissions.site.contractors.Delete,
                          this.props.stateData.cafmContractorsDelete,
                          (val) => this.props.setPermission({ cafmContractorsDelete: val.value }, 'cafm'),
                          this.props.getPermissionInfoForDropdownOptions(
                            cafmContractorsMenu,
                            permissions.site.contractors.Delete,
                          ),
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  <h3>Site Asset Register</h3>
                  <div className="form-group">
                    <FormLabel
                      label="View"
                      isShort
                      tooltipText={this.props.getPermissionInfoByKey(permissions.site.assetRegister.View)}
                    />
                    {this.props.renderSelector(
                      permissions.site.assetRegister.View,
                      cafmAssetRegisterView,
                      (val) => this.onChangeCafmModulePermissionView(val, 'assetregisterview'),
                      this.props.getPermissionInfoForDropdownOptions(
                        cafmMySiteSubMenu,
                        permissions.site.assetRegister.View,
                      ),
                    )}
                  </div>
                </div>
              </div>
            )}

            {cafmFeatureInfo.canSeeSiteAdmin && (
              <div>
                <h2>Site Admin</h2>
                <div className="form-group">
                  <FormLabel
                    label="Admin Access"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.site.Admin)}
                  />
                  {this.props.renderSelector(
                    permissions.site.Admin,
                    cafmAdmin,
                    (val) => this.onChangeCafmModulePermissionView(val, 'adminview'),
                    this.props.getPermissionInfoForDropdownOptions(cafmMySiteSubMenu, permissions.site.Admin),
                  )}
                </div>
                {cafmAdmin !== 'none' &&
                  this.props.renderCheckbox(
                    permissions.site.AdminActivity,
                    'View Activity',
                    !this.props.stateData.cafmViewSiteAdminActivity
                      ? false
                      : this.props.stateData.cafmViewSiteAdminActivity,
                    () =>
                      this.setPermission(
                        {
                          cafmViewSiteAdminActivity: !this.props.stateData.cafmViewSiteAdminActivity,
                        },
                        'cafm',
                      ),
                  )}
                <div className="form-group">
                  <FormLabel
                    label="Approval"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.site.Approval)}
                  />
                  {this.props.renderSelector(
                    permissions.site.Approval,
                    this.props.stateData.cafmSiteApproval,
                    (val) => this.props.setPermission({ cafmSiteApproval: val.value }, 'cafm'),
                    this.props.getPermissionInfoForDropdownOptions(cafmMySiteSubMenu, permissions.site.Approval),
                  )}
                </div>
                {cafmAdmin !== 'none' && (
                  <div>
                    <h3>Site Profile</h3>
                    {this.props.renderCheckbox(
                      permissions.site.ProfileActivity,
                      'View Profile Activity',
                      !this.props.stateData.cafmViewSiteProfileActivity
                        ? false
                        : this.props.stateData.cafmViewSiteProfileActivity,
                      () =>
                        this.setPermission(
                          {
                            cafmViewSiteProfileActivity: !this.props.stateData.cafmViewSiteProfileActivity,
                          },
                          'cafm',
                        ),
                    )}
                    {this.props.renderCheckbox(
                      permissions.site.reactiveSettings,
                      'Reactive Settings',
                      !this.props.stateData.cafmReactiveSettings ? false : this.props.stateData.cafmReactiveSettings,
                      () =>
                        this.setPermission(
                          {
                            cafmReactiveSettings: !this.props.stateData.cafmReactiveSettings,
                          },
                          'cafm',
                        ),
                    )}

                    {this.props.renderCheckbox(
                      permissions.site.postroomSettings,
                      'Postroom Settings',
                      !this.props.stateData.cafmPostroomSettings ? false : this.props.stateData.cafmPostroomSettings,
                      () =>
                        this.setPermission(
                          {
                            cafmPostroomSettings: !this.props.stateData.cafmPostroomSettings,
                          },
                          'cafm',
                        ),
                    )}

                    <h4>Postroom</h4>
                    <div className="form-group">
                      <FormLabel
                        label="View"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.postroom.View)}
                      />
                      {this.props.renderSelector(
                        permissions.site.postroom.View,
                        cafmPostroomView,
                        (val) => this.onChangeCafmModulePermissionView(val, 'postroomView'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmMySiteSubMenu,
                          permissions.site.postroom.View,
                        ),
                      )}
                    </div>
                    {cafmPostroomView !== 'none' && (
                      <div>
                        <div className="form-group">
                          <FormLabel
                            label="Edit"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.postroom.Edit)}
                          />
                          {this.props.renderSelector(
                            permissions.site.postroom.Edit,
                            this.props.stateData.cafmPostroomEdit,
                            (val) => this.props.setPermission({ cafmPostroomEdit: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmPostroomMenu,
                              permissions.site.postroom.Edit,
                            ),
                          )}
                        </div>
                        <div className="form-group">
                          <FormLabel
                            label="Create"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.postroom.Create)}
                          />
                          {this.props.renderSelector(
                            permissions.site.postroom.Create,
                            this.props.stateData.cafmPostroomCreate,
                            (val) => this.props.setPermission({ cafmPostroomCreate: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmPostroomMenu,
                              permissions.site.postroom.Create,
                            ),
                          )}
                        </div>
                        <div className="form-group">
                          <FormLabel
                            label="Delete"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.assets.Delete)}
                          />
                          {this.props.renderSelector(
                            permissions.site.postroom.Delete,
                            this.props.stateData.cafmPostroomDelete,
                            (val) => this.props.setPermission({ cafmPostroomDelete: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmPostroomMenu,
                              permissions.site.postroom.Delete,
                            ),
                          )}
                        </div>
                      </div>
                    )}

                    <h4>Reactive</h4>
                    <div className="form-group">
                      <FormLabel
                        label="View"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.reactive.View)}
                      />
                      {this.props.renderSelector(
                        permissions.site.reactive.View,
                        cafmReactiveView,
                        (val) => this.onChangeCafmModulePermissionView(val, 'reactiveView'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmMySiteSubMenu,
                          permissions.site.reactive.View,
                        ),
                      )}
                    </div>
                    {cafmReactiveView !== 'none' && (
                      <div>
                        <div className="form-group">
                          <FormLabel
                            label="Edit"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.reactive.Edit)}
                          />
                          {this.props.renderSelector(
                            permissions.site.reactive.Edit,
                            this.props.stateData.cafmReactiveEdit,
                            (val) => this.props.setPermission({ cafmReactiveEdit: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmReactiveMenu,
                              permissions.site.reactive.Edit,
                            ),
                          )}
                        </div>
                        <div className="form-group">
                          <FormLabel
                            label="Create"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.reactive.Create)}
                          />
                          {this.props.renderSelector(
                            permissions.site.reactive.Create,
                            this.props.stateData.cafmReactiveCreate,
                            (val) => this.props.setPermission({ cafmReactiveCreate: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmReactiveMenu,
                              permissions.site.reactive.Create,
                            ),
                          )}
                        </div>
                        <div className="form-group">
                          <FormLabel
                            label="Delete"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.assets.Delete)}
                          />
                          {this.props.renderSelector(
                            permissions.site.reactive.Delete,
                            this.props.stateData.cafmReactiveDelete,
                            (val) => this.props.setPermission({ cafmReactiveDelete: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmReactiveMenu,
                              permissions.site.reactive.Delete,
                            ),
                          )}
                        </div>
                      </div>
                    )}

                    <h4>Reports</h4>
                    {this.props.renderCheckbox(
                      permissions.site.reportGlobal,
                      'View Global',
                      !this.props.stateData.cafmReportGlobal ? false : this.props.stateData.cafmReportGlobal,
                      () =>
                        this.setPermission(
                          {
                            cafmReportGlobal: !this.props.stateData.cafmReportGlobal,
                          },
                          'cafm',
                        ),
                    )}
                    {this.props.renderCheckbox(
                      permissions.site.reportEngineer,
                      'View Engineer',
                      !this.props.stateData.cafmReportEngineer ? false : this.props.stateData.cafmReportEngineer,
                      () =>
                        this.setPermission(
                          {
                            cafmReportEngineer: !this.props.stateData.cafmReportEngineer,
                          },
                          'cafm',
                        ),
                    )}
                    {this.props.renderCheckbox(
                      permissions.site.reportTimeSpent,
                      'View Time Spent',
                      !this.props.stateData.cafmReportTimeSpent ? false : this.props.stateData.cafmReportTimeSpent,
                      () =>
                        this.setPermission(
                          {
                            cafmReportTimeSpent: !this.props.stateData.cafmReportTimeSpent,
                          },
                          'cafm',
                        ),
                    )}

                    <h4>Site Assets</h4>
                    <div className="form-group">
                      <FormLabel
                        label="View"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.assets.View)}
                      />
                      {this.props.renderSelector(
                        permissions.site.assets.View,
                        cafmAssetsView,
                        (val) => this.onChangeCafmModulePermissionView(val, 'assetview'),
                        this.props.getPermissionInfoForDropdownOptions(cafmMySiteSubMenu, permissions.site.assets.View),
                      )}
                    </div>
                    {cafmAssetsView !== 'none' && (
                      <div>
                        <div className="form-group">
                          <FormLabel
                            label="Edit"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.assets.Edit)}
                          />
                          {this.props.renderSelector(
                            permissions.site.assets.Edit,
                            this.props.stateData.cafmAssetsEdit,
                            (val) => this.props.setPermission({ cafmAssetsEdit: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmAssetsMenu,
                              permissions.site.assets.Edit,
                            ),
                          )}
                        </div>
                        <div className="form-group">
                          <FormLabel
                            label="Create"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.assets.Create)}
                          />
                          {this.props.renderSelector(
                            permissions.site.assets.Create,
                            this.props.stateData.cafmAssetsCreate,
                            (val) => this.props.setPermission({ cafmAssetsCreate: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmAssetsMenu,
                              permissions.site.assets.Create,
                            ),
                          )}
                        </div>
                        <div className="form-group">
                          <FormLabel
                            label="Delete"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.assets.Delete)}
                          />
                          {this.props.renderSelector(
                            permissions.site.assets.Delete,
                            this.props.stateData.cafmAssetsDelete,
                            (val) => this.props.setPermission({ cafmAssetsDelete: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmAssetsMenu,
                              permissions.site.assets.Delete,
                            ),
                          )}
                        </div>
                      </div>
                    )}
                    <h4>Site Asset Survey</h4>
                    <div className="form-group">
                      <FormLabel
                        label="View"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.assetSurvey.View)}
                      />
                      {this.props.renderSelector(
                        permissions.site.assetSurvey.View,
                        cafmAssetSurveyView,
                        (val) => this.onChangeCafmModulePermissionView(val, 'assetSurvey'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmMySiteSubMenu,
                          permissions.site.assetSurvey.View,
                        ),
                      )}
                    </div>
                    {cafmAssetSurveyView !== 'none' && (
                      <div>
                        <div className="form-group">
                          <FormLabel
                            label="Edit"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.assetSurvey.EditAll)}
                          />
                          {this.props.renderSelector(
                            permissions.site.assetSurvey.EditAll,
                            this.props.stateData.cafmAssetSurveyEdit,
                            (val) => this.props.setPermission({ cafmAssetSurveyEdit: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmAssetSurveyMenu,
                              permissions.site.assetSurvey.EditAll,
                            ),
                          )}
                        </div>
                        <div className="form-group">
                          <FormLabel
                            label="Create"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.assetSurvey.Create)}
                          />
                          {this.props.renderSelector(
                            permissions.site.assetSurvey.Create,
                            this.props.stateData.cafmAssetSurveyCreate,
                            (val) => this.props.setPermission({ cafmAssetSurveyCreate: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmAssetSurveyMenu,
                              permissions.site.assetSurvey.Create,
                            ),
                          )}
                        </div>
                        <div className="form-group">
                          <FormLabel
                            label="Delete"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.assetSurvey.DeleteAll)}
                          />
                          {this.props.renderSelector(
                            permissions.site.assetSurvey.DeleteAll,
                            this.props.stateData.cafmAssetSurveyDelete,
                            (val) => this.props.setPermission({ cafmAssetSurveyDelete: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmAssetSurveyMenu,
                              permissions.site.assetSurvey.DeleteAll,
                            ),
                          )}
                        </div>
                        <div className="form-group">
                          <FormLabel
                            label="Approve"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.assetSurvey.ApprovalAll)}
                          />
                          {this.props.renderSelector(
                            permissions.site.assetSurvey.ApprovalAll,
                            this.props.stateData.cafmAssetSurveyApprove,
                            (val) => this.props.setPermission({ cafmAssetSurveyApprove: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmAssetSurveyMenu,
                              permissions.site.assetSurvey.ApprovalAll,
                            ),
                          )}
                        </div>
                      </div>
                    )}
                    <h4>Site Locations</h4>
                    <div className="form-group">
                      <FormLabel
                        label="View"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.locations.View)}
                      />
                      {this.props.renderSelector(
                        permissions.site.locations.View,
                        cafmLocationsView,
                        (val) => this.onChangeCafmModulePermissionView(val, 'locationview'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmMySiteSubMenu,
                          permissions.site.locations.View,
                        ),
                      )}
                    </div>
                    {cafmLocationsView !== 'none' && (
                      <div>
                        <div className="form-group">
                          <FormLabel
                            label="Edit"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.locations.Edit)}
                          />
                          {this.props.renderSelector(
                            permissions.site.locations.Edit,
                            this.props.stateData.cafmLocationsEdit,
                            (val) => this.props.setPermission({ cafmLocationsEdit: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmLocationsMenu,
                              permissions.site.locations.Edit,
                            ),
                          )}
                        </div>
                        <div className="form-group">
                          <FormLabel
                            label="Create"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.locations.Create)}
                          />
                          {this.props.renderSelector(
                            permissions.site.locations.Create,
                            this.props.stateData.cafmLocationsCreate,
                            (val) => this.props.setPermission({ cafmLocationsCreate: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmLocationsMenu,
                              permissions.site.locations.Create,
                            ),
                          )}
                        </div>
                        <div className="form-group">
                          <FormLabel
                            label="Delete"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.locations.Delete)}
                          />
                          {this.props.renderSelector(
                            permissions.site.locations.Delete,
                            this.props.stateData.cafmLocationsDelete,
                            (val) => this.props.setPermission({ cafmLocationsDelete: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmLocationsMenu,
                              permissions.site.locations.Delete,
                            ),
                          )}
                        </div>
                      </div>
                    )}

                    {/* <div className="form-group">
                      <h3>Site Notifications</h3>
                      <FormLabel label={'View'} /> {
                        this.props.renderSelector(permissions.site.notifications.View, cafmNotificationsView,
                          (val) => this.onChangeCafmModulePermissionView(val, "notificationview"), cafmMySiteSubMenu)
                      }
                    </div>
                    {
                      (cafmNotificationsView !== 'none') &&
                      <div className="form-group">
                        <FormLabel label={'Edit'} /> {
                          this.props.renderSelector(permissions.site.notifications.Edit, this.props.stateData.cafmNotificationsEdit,
                            (val) => this.props.setPermission({ cafmNotificationsEdit: val }, "cafm"), cafmNotificationsMenu)
                        }
                        <FormLabel label={'Create'} /> {
                          this.props.renderSelector(permissions.site.notifications.Create, this.props.stateData.cafmNotificationsCreate,
                            (val) => this.props.setPermission({ cafmNotificationsCreate: val }, "cafm"), cafmNotificationsMenu)
                        }
                        <FormLabel label={'Delete'} /> {
                          this.props.renderSelector(permissions.site.notifications.Delete, this.props.stateData.cafmNotificationsDelete,
                            (val) => this.props.setPermission({ cafmNotificationsDelete: val }, "cafm"), cafmNotificationsMenu)
                        }
                        </div>
                    } */}
                    <h4>Maintenance Schedule</h4>
                    <div className="form-group">
                      <FormLabel
                        label="View"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.schedule.View)}
                      />
                      {this.props.renderSelector(
                        permissions.site.schedule.View,
                        cafmScheduleView,
                        (val) => this.onChangeCafmModulePermissionView(val, 'scheduleview'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmMySiteSubMenu,
                          permissions.site.schedule.View,
                        ),
                      )}
                    </div>
                    {cafmScheduleView !== 'none' && (
                      <div>
                        <div className="form-group">
                          <FormLabel
                            label="Edit"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.schedule.Edit)}
                          />
                          {this.props.renderSelector(
                            permissions.site.schedule.Edit,
                            this.props.stateData.cafmScheduleEdit,
                            (val) => this.props.setPermission({ cafmScheduleEdit: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmScheduleMenu,
                              permissions.site.schedule.Edit,
                            ),
                          )}
                        </div>
                        <div className="form-group">
                          <FormLabel
                            label="Create"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.schedule.Create)}
                          />
                          {this.props.renderSelector(
                            permissions.site.schedule.Create,
                            this.props.stateData.cafmScheduleCreate,
                            (val) => this.props.setPermission({ cafmScheduleCreate: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmScheduleMenu,
                              permissions.site.schedule.Create,
                            ),
                          )}
                        </div>
                        <div className="form-group">
                          <FormLabel
                            label="Delete"
                            isShort
                            tooltipText={this.props.getPermissionInfoByKey(permissions.site.schedule.Delete)}
                          />
                          {this.props.renderSelector(
                            permissions.site.schedule.Delete,
                            this.props.stateData.cafmScheduleDelete,
                            (val) => this.props.setPermission({ cafmScheduleDelete: val.value }, 'cafm'),
                            this.props.getPermissionInfoForDropdownOptions(
                              cafmScheduleMenu,
                              permissions.site.schedule.Delete,
                            ),
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            {cafmFeatureInfo.canSeePPMCalendar && (
              <div>
                <h2>PPM Calendar</h2>
                <h3>Site Calendar</h3>
                <div className="form-group">
                  <FormLabel
                    label="View"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.site.calendar.View)}
                  />
                  {this.props.renderSelector(
                    permissions.site.calendar.View,
                    cafmCalendarView,
                    (val) => this.onChangeCafmModulePermissionView(val, 'calendarview'),
                    this.props.getPermissionInfoForDropdownOptions(cafmMySiteSubMenu, permissions.site.calendar.View),
                  )}
                </div>
                {cafmCalendarView !== 'none' && (
                  <div>
                    <div className="form-group">
                      <FormLabel
                        label="View Tasks"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.calendar.ViewTasks)}
                      />
                      {this.props.renderSelector(
                        permissions.site.calendar.ViewTasks,
                        this.props.stateData.cafmCalendarTasksView,
                        (val) => this.props.setPermission({ cafmCalendarTasksView: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmCalendarMenu,
                          permissions.site.calendar.ViewTasks,
                        ),
                      )}
                    </div>
                    <div className="form-group">
                      <FormLabel
                        label="View Detail"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.calendar.ViewEngineer)}
                      />
                      {this.props.renderSelector(
                        permissions.site.calendar.ViewEngineer,
                        this.props.stateData.cafmCalendarEngineerView,
                        (val) => this.props.setPermission({ cafmCalendarEngineerView: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmCalendarMenu,
                          permissions.site.calendar.ViewEngineer,
                        ),
                      )}
                    </div>
                    <div className="form-group">
                      <FormLabel
                        label="View As Engineer"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.calendar.ViewTask)}
                      />
                      {this.props.renderSelector(
                        permissions.site.calendar.ViewTask,
                        this.props.stateData.cafmCalendarDetailView,
                        (val) => this.props.setPermission({ cafmCalendarDetailView: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmCalendarMenu,
                          permissions.site.calendar.ViewTask,
                        ),
                      )}
                    </div>
                    <div className="form-group">
                      <FormLabel
                        label="Edit"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.calendar.Edit)}
                      />
                      {this.props.renderSelector(
                        permissions.site.calendar.Edit,
                        this.props.stateData.cafmCalendarEdit,
                        (val) => this.props.setPermission({ cafmCalendarEdit: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmCalendarMenu,
                          permissions.site.calendar.Edit,
                        ),
                      )}
                    </div>
                    {/* <FormLabel label={'Edit As Engineer'} /> {
                      this.props.renderSelector(permissions.site.calendar.EditEngineer, this.props.stateData.cafmCalendarEngineerEdit,
                        (val) => this.props.setPermission({ cafmCalendarEngineerEdit: val }, "cafm"), cafmCalendarMenu)
                    } */}
                    <div className="form-group">
                      <FormLabel
                        label="Create Tasks"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.calendar.CreateTasks)}
                      />
                      {this.props.renderSelector(
                        permissions.site.calendar.CreateTasks,
                        this.props.stateData.cafmCalendarTasksCreate,
                        (val) => this.props.setPermission({ cafmCalendarTasksCreate: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmCalendarMenu,
                          permissions.site.calendar.CreateTasks,
                        ),
                      )}
                    </div>

                    <div className="form-group">
                      <FormLabel
                        label="Edit Comment"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.calendar.CommentEdit)}
                      />
                      {this.props.renderSelector(
                        permissions.site.calendar.CommentEdit,
                        this.props.stateData.cafmCalendarTaskCommentEdit,
                        (val) => this.props.setPermission({ cafmCalendarTaskCommentEdit: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmCalendarMenu,
                          permissions.site.calendar.CommentEdit,
                        ),
                      )}
                    </div>
                    <div className="form-group">
                      <FormLabel
                        label="Delete Comment"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.calendar.CommentDelete)}
                      />
                      {this.props.renderSelector(
                        permissions.site.calendar.CommentDelete,
                        this.props.stateData.cafmCalendarTaskCommentDelete,
                        (val) => this.props.setPermission({ cafmCalendarTaskCommentDelete: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmCalendarMenu,
                          permissions.site.calendar.CommentDelete,
                        ),
                      )}
                    </div>

                    {/* {this.props.renderCheckbox(
                      permissions.site.helpdesk.CreateIssue,
                      'Manager Notification',
                      this.props.stateData.cafmCalendarManagerNotification,
                      () => this.props.setPermission(
                        { cafmCalendarManagerNotification: !this.props.stateData.cafmCalendarManagerNotification },
                        'cafm',
                      ),
                    )} */}
                  </div>
                )}
              </div>
            )}

            {cafmFeatureInfo.canSeeHelpdesk && (
              <div>
                <h2>Helpdesk</h2>
                <h3>Site Helpdesk</h3>
                <div className="form-group">
                  <FormLabel
                    label="View Helpdesk"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.site.helpdesk.View)}
                  />
                  {this.props.renderSelector(
                    permissions.site.helpdesk.View,
                    cafmHelpdeskView,
                    (val) => this.onChangeCafmModulePermissionView(val, 'helpdeskview'),
                    this.props.getPermissionInfoForDropdownOptions(cafmMySiteSubMenu, permissions.site.helpdesk.View),
                  )}
                </div>
                {cafmHelpdeskView !== 'none' && (
                  <div>
                    {this.props.renderCheckbox(
                      permissions.site.helpdesk.CreateIssue,
                      'Create Issue',
                      this.props.stateData.cafmHelpdeskIssueCreate,
                      () =>
                        this.props.setPermission(
                          { cafmHelpdeskIssueCreate: !this.props.stateData.cafmHelpdeskIssueCreate },
                          'cafm',
                        ),
                    )}
                    <div className="form-group">
                      <FormLabel
                        label="Comment"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.helpdesk.Comment)}
                      />
                      {this.props.renderSelector(
                        permissions.site.helpdesk.Comment,
                        this.props.stateData.cafmHelpdeskIssueComment,
                        (val) => this.props.setPermission({ cafmHelpdeskIssueComment: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmHelpdeskMenu,
                          permissions.site.helpdesk.Comment,
                        ),
                      )}
                    </div>
                    <div className="form-group">
                      <FormLabel
                        label="Cancel"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.helpdesk.Cancel)}
                      />
                      {this.props.renderSelector(
                        permissions.site.helpdesk.Cancel,
                        this.props.stateData.cafmHelpdeskIssueCancel,
                        (val) => this.props.setPermission({ cafmHelpdeskIssueCancel: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmHelpdeskMenu,
                          permissions.site.helpdesk.Cancel,
                        ),
                      )}
                    </div>

                    <div className="form-group">
                      <FormLabel
                        label="Delete"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.helpdesk.Delete)}
                      />
                      {this.props.renderSelector(
                        permissions.site.helpdesk.Delete,
                        this.props.stateData.cafmHelpdeskIssueDelete,
                        (val) => this.props.setPermission({ cafmHelpdeskIssueDelete: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmHelpdeskMenu,
                          permissions.site.helpdesk.Delete,
                        ),
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {cafmFeatureInfo.canSeeAdvancedTicketing && (
              <div>
                <h2>Advanced Ticketing</h2>
                <h3>Site as Engineer</h3>
                <div className="form-group">
                  <FormLabel
                    label="Advanced Ticketing Button"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.site.engineer.View)}
                  />
                  {this.props.renderSelector(
                    permissions.site.engineer.View,
                    cafmEngineerView,
                    (val) => this.onChangeCafmModulePermissionView(val, 'engineerview'),
                    this.props.getPermissionInfoForDropdownOptions(cafmMySiteSubMenu, permissions.site.engineer.View),
                  )}
                </div>
                {/* {
                  (cafmEngineerView !== 'none') &&
                  <div className="form-group">
                    <FormLabel label={'View Issues'} /> {
                      this.props.renderSelector(permissions.site.engineer.ViewIssues, this.props.stateData.cafmEngineerIssuesView,
                        (val) => this.props.setPermission({ cafmEngineerIssuesView: val }, "cafm"), cafmEngineerMenu)
                    }
                    <FormLabel label={'View Issue'} /> {
                      this.props.renderSelector(permissions.site.engineer.ViewIssue, this.props.stateData.cafmEngineerIssueView,
                        (val) => this.props.setPermission({ cafmEngineerIssueView: val }, "cafm"), cafmEngineerMenu)
                    }
                    <FormLabel label={'Edit'} /> {
                      this.props.renderSelector(permissions.site.engineer.Edit, this.props.stateData.cafmEngineerEdit,
                        (val) => this.props.setPermission({ cafmEngineerEdit: val }, "cafm"), cafmEngineerMenu)
                    }
                    <FormLabel label={'Log Time'} /> {
                      this.props.renderSelector(permissions.site.engineer.LogTime, this.props.stateData.cafmEngineerLogTime,
                        (val) => this.props.setPermission({ cafmEngineerLogTime: val }, "cafm"), cafmEngineerMenu)
                    }
                    </div>
                } */}
              </div>
            )}

            {cafmFeatureInfo.canSeeDocuments && (
              <div>
                <h2>Documents</h2>
                <h3>Site Documents</h3>
                <div className="form-group">
                  <FormLabel
                    label="View Documents"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.site.documents.View)}
                  />
                  {this.props.renderSelector(
                    permissions.site.documents.View,
                    cafmDocumentsView,
                    (val) => this.onChangeCafmModulePermissionView(val, 'documentview'),
                    this.props.getPermissionInfoForDropdownOptions(cafmMySiteSubMenu, permissions.site.documents.View),
                  )}
                </div>
                {cafmDocumentsView !== 'none' && (
                  <div>
                    <div className="form-group">
                      <FormLabel
                        label="Download"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.documents.Download)}
                      />
                      {this.props.renderSelector(
                        permissions.site.documents.Download,
                        this.props.stateData.cafmDocumentsDownload,
                        (val) => this.props.setPermission({ cafmDocumentsDownload: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmDocumentsMenu,
                          permissions.site.documents.Download,
                        ),
                      )}
                    </div>
                    {this.props.renderCheckbox(
                      permissions.site.documents.Create,
                      'Upload File',
                      this.props.stateData.cafmDocumentsCreate,
                      () =>
                        this.props.setPermission(
                          { cafmDocumentsCreate: !this.props.stateData.cafmDocumentsCreate },
                          'cafm',
                        ),
                    )}
                    <div className="form-group">
                      <FormLabel
                        label="Delete"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.documents.Delete)}
                      />
                      {this.props.renderSelector(
                        permissions.site.documents.Delete,
                        this.props.stateData.cafmDocumentsDelete,
                        (val) => this.props.setPermission({ cafmDocumentsDelete: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmDocumentsMenu,
                          permissions.site.documents.Delete,
                        ),
                      )}
                    </div>
                    {this.props.renderCheckbox(
                      permissions.site.documents.CreateFolder,
                      'Folder Create',
                      this.props.stateData.cafmDocumentsFolderCreate,
                      () =>
                        this.props.setPermission(
                          {
                            cafmDocumentsFolderCreate: !this.props.stateData.cafmDocumentsFolderCreate,
                          },
                          'cafm',
                        ),
                    )}
                    <div className="form-group">
                      <FormLabel
                        label="Folder Delete"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.documents.DeleteFolder)}
                      />
                      {this.props.renderSelector(
                        permissions.site.documents.DeleteFolder,
                        this.props.stateData.cafmDocumentsFolderDelete,
                        (val) => this.props.setPermission({ cafmDocumentsFolderDelete: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmDocumentsMenu,
                          permissions.site.documents.DeleteFolder,
                        ),
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {cafmFeatureInfo.canSeeInduction && (
              <div>
                <h2>Induction</h2>
                <h3>Site Induction</h3>
                <div className="form-group">
                  <FormLabel
                    label="View"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.site.induction.View)}
                  />
                  {this.props.renderSelector(
                    permissions.site.induction.View,
                    cafmInductionView,
                    (val) => this.onChangeCafmModulePermissionView(val, 'inductionview'),
                    this.props.getPermissionInfoForDropdownOptions(cafmMySiteSubMenu, permissions.site.induction.View),
                  )}
                </div>
                {cafmInductionView !== 'none' && (
                  <div>
                    <div className="form-group">
                      <FormLabel
                        label="Edit"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.induction.Edit)}
                      />
                      {this.props.renderSelector(
                        permissions.site.induction.Edit,
                        this.props.stateData.cafmInductionEdit,
                        (val) => this.props.setPermission({ cafmInductionEdit: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmInductionMenu,
                          permissions.site.induction.Edit,
                        ),
                      )}
                    </div>
                    {this.props.renderCheckbox(
                      permissions.site.induction.Create,
                      'Create',
                      this.props.stateData.cafmInductionCreate,
                      () =>
                        this.props.setPermission(
                          { cafmInductionCreate: !this.props.stateData.cafmInductionCreate },
                          'cafm',
                        ),
                    )}
                    <div className="form-group">
                      <FormLabel
                        label="Delete"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.induction.Delete)}
                      />
                      {this.props.renderSelector(
                        permissions.site.induction.Delete,
                        this.props.stateData.cafmInductionDelete,
                        (val) => this.props.setPermission({ cafmInductionDelete: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(
                          cafmInductionMenu,
                          permissions.site.induction.Delete,
                        ),
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {cafmFeatureInfo.canSeeRisk && (
              <div>
                <h2>Risk</h2>
                <h3>Site Risk</h3>
                <div className="form-group">
                  <FormLabel
                    label="View"
                    isShort
                    tooltipText={this.props.getPermissionInfoByKey(permissions.site.risk.View)}
                  />
                  {this.props.renderSelector(
                    permissions.site.risk.View,
                    cafmRiskView,
                    (val) => this.onChangeCafmModulePermissionView(val, 'riskview'),
                    this.props.getPermissionInfoForDropdownOptions(cafmMySiteSubMenu, permissions.site.risk.View),
                  )}
                </div>
                {cafmRiskView !== 'none' && (
                  <div>
                    <div className="form-group">
                      <FormLabel
                        label="Edit"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.risk.Edit)}
                      />
                      {this.props.renderSelector(
                        permissions.site.risk.Edit,
                        this.props.stateData.cafmRiskEdit,
                        (val) => this.props.setPermission({ cafmRiskEdit: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(cafmRiskMenu, permissions.site.risk.Edit),
                      )}
                    </div>
                    {this.props.renderCheckbox(
                      permissions.site.risk.Create,
                      'Create',
                      this.props.stateData.cafmRiskCreate,
                      () => this.props.setPermission({ cafmRiskCreate: !this.props.stateData.cafmRiskCreate }, 'cafm'),
                    )}
                    <div className="form-group">
                      <FormLabel
                        label="Delete"
                        isShort
                        tooltipText={this.props.getPermissionInfoByKey(permissions.site.risk.Delete)}
                      />
                      {this.props.renderSelector(
                        permissions.site.risk.Delete,
                        this.props.stateData.cafmRiskDelete,
                        (val) => this.props.setPermission({ cafmRiskDelete: val.value }, 'cafm'),
                        this.props.getPermissionInfoForDropdownOptions(cafmRiskMenu, permissions.site.risk.Delete),
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {cafmAccess && cafmFeatureInfo.canSeeCafmCompanies && (
          <div>
            <h3>Companies</h3>
            <div className="form-group">
              <FormLabel label="View" isShort />{' '}
              {this.props.renderSelector(
                permissions.cafm.companies.View,
                cafmCompaniesView,
                (val) => this.onChangeCafmModulePermissionView(val, 'cafmcompanyview'),
                this.props.getPermissionInfoForDropdownOptions(cafmCompaniesMenu, permissions.cafm.companies.View),
              )}
            </div>
            {cafmFeatureInfo.canSeeCafmCompanies && cafmCompaniesView && cafmCompaniesView !== 'none' && (
              <div>
                <div className="form-group">
                  <FormLabel label="Edit" isShort />{' '}
                  {this.props.renderSelector(
                    permissions.cafm.companies.Edit,
                    this.props.stateData.cafmCompaniesEdit,
                    (val) => this.props.setPermission({ cafmCompaniesEdit: val.value }, 'cafm'),
                    this.props.getPermissionInfoForDropdownOptions(cafmCompaniesMenu, permissions.cafm.companies.Edit),
                  )}
                </div>
                {this.props.renderCheckbox(
                  permissions.cafm.companies.Create,
                  'Create',
                  !this.props.stateData.cafmCompaniesCreate ? false : this.props.stateData.cafmCompaniesCreate,
                  () =>
                    this.setPermission(
                      {
                        cafmCompaniesCreate: !this.props.stateData.cafmCompaniesCreate,
                      },
                      'cafm',
                    ),
                )}
                <div className="form-group">
                  <FormLabel label="Delete" isShort />{' '}
                  {this.props.renderSelector(
                    permissions.cafm.companies.Delete,
                    this.props.stateData.cafmCompaniesDelete,
                    (val) => this.props.setPermission({ cafmCompaniesDelete: val.value }, 'cafm'),
                    this.props.getPermissionInfoForDropdownOptions(
                      cafmCompaniesMenu,
                      permissions.cafm.companies.Delete,
                    ),
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

RolePermissionsFields.propTypes = {
  t: PropTypes.func.isRequired,
  handlePermisssionUpdate: PropTypes.func.isRequired,
  permissionsListData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedPermissions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChangeCafmModulePermissionView: PropTypes.func,
  stateData: PropTypes.arrayOf(PropTypes.shape()),
  setPermission: PropTypes.func,
  renderCheckbox: PropTypes.func.isRequired,
  renderSelector: PropTypes.func.isRequired,
  getPermissionInfoByKey: PropTypes.func,
  getPermissionInfoForDropdownOptions: PropTypes.func,
  entitledApplicationFeatureList: PropTypes.arrayOf(PropTypes.shape()),
};

RolePermissionsFields.defaultProps = {
  handlePermisssionUpdate: undefined,
  permissionsListData: [],
  selectedPermissions: [],
  loadSubscriptionProductList: undefined,
  onChangeCafmModulePermissionView: undefined,
  stateData: [],
  setPermission: undefined,
  getPermissionInfoByKey: undefined,
  getPermissionInfoForDropdownOptions: undefined,
  entitledApplicationFeatureList: [],
};

const mapStateToProps = (state, props) => {
  const {
    subscription: { entitledApplicationFeatureList },
  } = state;

  return {
    entitledApplicationFeatureList,
  };
};

export default connect(mapStateToProps)(RolePermissionsFields);
