import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { permissions } from '../../constants/permissions';
import { FormLabel } from './parts';
import {
  loadSubscriptionProductList,
  getEntitledAccountApplicationFeature,
} from '../../actions/account/SubscriptionActions';

class GeneralRolePermissionsFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.stateData,
    };
  }

  componentDidMount() {
    this.props.loadSubscriptionProductList(this.props.user.user.uuid);
    this.props.getEntitledAccountApplicationFeature({
      accountUUID: this.props.user.user.uuid,
      applicationId: 0, // to pick all applications entitled Feature & use data from state>prop in respective tab
    });
  }

  onChangeCafmModulePermissionView = (value, flag) => {
    value = typeof value === 'object' ? value.value : value;
    this.props.onChangeCafmModulePermissionView(value, flag);
  };

  setPermission = (value) => {
    this.props.setPermission(value, 'cafm');
  };

  render() {
    const { accountSubscriptionProductList } = this.props;
    let { coreRolesView, coreTagsView } = this.props.stateData;

    coreRolesView = !coreRolesView ? 'none' : coreRolesView;
    coreTagsView = !coreTagsView ? 'none' : coreTagsView;

    const canAccessCafm = accountSubscriptionProductList.some((p) => p.id === 1 && p.ApplicationAccount.length > 0);
    const canAccessAlp = accountSubscriptionProductList.some((p) => p.id === 2 && p.ApplicationAccount.length > 0);
    const canAccessJms = accountSubscriptionProductList.some((p) => p.id === 5 && p.ApplicationAccount.length > 0);

    const memberMinMenu = [
      { key: 'none', value: 'no access' },
      { key: 'own', value: 'access to assigned only' },
    ];
    const memberFullMenu = [...memberMinMenu, { key: 'all', value: 'access to all' }];
    const coreRolesMenu = coreRolesView === 'own' ? memberMinMenu : memberFullMenu;
    const coreTagsMenu = coreTagsView === 'own' ? memberMinMenu : memberFullMenu;

    return (
      <div className="p-sm">
        <div className="form-group">
          <h3>Account</h3>
          {this.props.renderCheckbox(
            permissions.accounts.Edit,
            'Manage Account',
            !this.props.stateData.coreAccountsEdit ? false : this.props.stateData.coreAccountsEdit,
            () => this.props.setPermission({ coreAccountsEdit: !this.props.stateData.coreAccountsEdit }, 'accounts'),
          )}
        </div>
        <div className="form-group">
          <h3>Applications</h3>
          {canAccessCafm &&
            this.props.renderCheckbox(
              permissions.subscriptions.Cafm,
              'CAFM Access',
              this.props.stateData.cafmAccess,
              (val) => this.onChangeCafmModulePermissionView(val, 'cafm'),
            )}
          {canAccessJms &&
            this.props.renderCheckbox(
              permissions.subscriptions.Jms,
              'JMS Access',
              this.props.stateData.jmsAccess,
              (val) => this.onChangeCafmModulePermissionView(val, 'jms'),
            )}
          {canAccessAlp &&
            this.props.renderCheckbox(
              permissions.subscriptions.Alp,
              'ALP Access',
              this.props.stateData.alpAccess,
              (val) => this.onChangeCafmModulePermissionView(val, 'alp'),
            )}
        </div>
        <div className="form-group">
          <h3>Roles</h3>
          <FormLabel
            label="View"
            isShort
            tooltipText={this.props.getPermissionInfoByKey(permissions.roles.View)}
          />{' '}
          {this.props.renderSelector(
            permissions.roles.View,
            !coreRolesView ? 'none' : coreRolesView,
            (val) => this.onChangeCafmModulePermissionView(val, 'roles'),
            this.props.getPermissionInfoForDropdownOptions(memberFullMenu, permissions.roles.View),
          )}
        </div>
        {coreRolesView && coreRolesView !== 'none' && (
          <div>
            {this.props.renderCheckbox(
              permissions.roles.Create,
              'Create',
              !this.props.stateData.coreRolesCreate ? false : this.props.stateData.coreRolesCreate,
              () => this.props.setPermission({ coreRolesCreate: !this.props.stateData.coreRolesCreate }, 'general'),
            )}
            <div className="form-group">
              <FormLabel label="Edit" isShort tooltipText={this.props.getPermissionInfoByKey(permissions.roles.Edit)} />{' '}
              {this.props.renderSelector(
                permissions.roles.Edit,
                this.props.stateData.coreRolesEdit,
                (val) => this.props.setPermission({ coreRolesEdit: val.value }, 'general'),
                this.props.getPermissionInfoForDropdownOptions(coreRolesMenu, permissions.roles.Edit),
              )}
            </div>
            <div className="form-group">
              <FormLabel
                label="Delete"
                isShort
                tooltipText={this.props.getPermissionInfoByKey(permissions.roles.Delete)}
              />{' '}
              {this.props.renderSelector(
                permissions.roles.Delete,
                this.props.stateData.coreRolesDelete,
                (val) => this.props.setPermission({ coreRolesDelete: val.value }, 'general'),
                this.props.getPermissionInfoForDropdownOptions(coreRolesMenu, permissions.roles.Delete),
              )}
            </div>
          </div>
        )}

        <div className="form-group">
          <h3>Tags</h3>
          <FormLabel label="View" isShort tooltipText={this.props.getPermissionInfoByKey(permissions.tags.View)} />{' '}
          {this.props.renderSelector(
            permissions.tags.View,
            !coreTagsView ? 'none' : coreTagsView,
            (val) => this.onChangeCafmModulePermissionView(val, 'tags'),
            this.props.getPermissionInfoForDropdownOptions(memberFullMenu, permissions.tags.View),
          )}
        </div>
        {coreTagsView && coreTagsView !== 'none' && (
          <div>
            {this.props.renderCheckbox(
              permissions.tags.Create,
              'Create',
              !this.props.stateData.coreTagsCreate ? false : this.props.stateData.coreTagsCreate,
              () => this.props.setPermission({ coreTagsCreate: !this.props.stateData.coreTagsCreate }, 'general'),
            )}
            <div className="form-group">
              <FormLabel label="Edit" isShort tooltipText={this.props.getPermissionInfoByKey(permissions.tags.Edit)} />{' '}
              {this.props.renderSelector(
                permissions.tags.Edit,
                this.props.stateData.coreTagsEdit,
                (val) => this.props.setPermission({ coreTagsEdit: val.value }, 'general'),
                this.props.getPermissionInfoForDropdownOptions(coreTagsMenu, permissions.tags.Edit),
              )}
            </div>
            <div className="form-group">
              <FormLabel
                label="Delete"
                isShort
                tooltipText={this.props.getPermissionInfoByKey(permissions.tags.Delete)}
              />{' '}
              {this.props.renderSelector(
                permissions.tags.Delete,
                this.props.stateData.coreTagsDelete,
                (val) => this.props.setPermission({ coreTagsDelete: val.value }, 'general'),
                this.props.getPermissionInfoForDropdownOptions(coreTagsMenu, permissions.tags.Delete),
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

GeneralRolePermissionsFields.propTypes = {
  t: PropTypes.func.isRequired,
  handlePermisssionUpdate: PropTypes.func.isRequired,
  permissionsListData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedPermissions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loadSubscriptionProductList: PropTypes.func.isRequired,
  accountSubscriptionProductList: PropTypes.any,
  onChangeCafmModulePermissionView: PropTypes.func,
  stateData: PropTypes.arrayOf(PropTypes.shape()),
  setPermission: PropTypes.func,
  renderCheckbox: PropTypes.func.isRequired,
  renderSelector: PropTypes.func.isRequired,
  getPermissionInfoByKey: PropTypes.func,
  getPermissionInfoForDropdownOptions: PropTypes.func,
  getEntitledAccountApplicationFeature: PropTypes.func,
  entitledApplicationFeatureList: PropTypes.arrayOf(PropTypes.shape()),
};

GeneralRolePermissionsFields.defaultProps = {
  handlePermisssionUpdate: undefined,
  permissionsListData: [],
  selectedPermissions: [],
  loadSubscriptionProductList: undefined,
  accountSubscriptionProductList: {},
  onChangeCafmModulePermissionView: undefined,
  stateData: [],
  setPermission: undefined,
  renderCheckbox: undefined,
  renderSelector: undefined,
  getPermissionInfoByKey: undefined,
  getPermissionInfoForDropdownOptions: undefined,
  getEntitledAccountApplicationFeature: undefined,
  entitledApplicationFeatureList: [],
};

const mapStateToProps = (state, props) => {
  const {
    subscription: { accountSubscriptionProductList, entitledApplicationFeatureList },
    user,
  } = state;

  return {
    user,
    accountSubscriptionProductList,
    entitledApplicationFeatureList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadSubscriptionProductList,
      getEntitledAccountApplicationFeature,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(GeneralRolePermissionsFields);
